import Vue from "vue";

Vue.directive("click-outside", {
  bind: (el, binding, vnode) => {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression]();
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: (el) => {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

Vue.directive("observe-height", {
  bind: (el, binding, vnode) => {
    let prevHeight = 0;
    el.observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const height = entry.borderBoxSize?.[0].blockSize;
        if (typeof height === "number" && height !== prevHeight) {
          prevHeight = height;
          vnode.context[binding.expression](el);
        }
      }
    });
    el.observer.observe(el);
  },
  unbind: (el) => {
    el.observer.unobserve(el);
  },
});

Vue.directive("observe-mounted", {
  bind: (_, binding, vnode) => {
    setTimeout(() => {
      vnode.context[binding.expression]();
    }, 100);
  },
});

Vue.directive("observe-scroll", {
  bind: (el, binding, vnode) => {
    el.scrollEvent = function () {
      vnode.context[binding.expression]();
    };
    window.addEventListener("scroll", el.scrollEvent, true);
  },
  unbind: (el) => {
    window.removeEventListener("scroll", el.scrollEvent, true);
  },
});

Vue.directive("observe-size", {
  bind: (el, binding, vnode) => {
    el.observer = new ResizeObserver(() => {
      vnode.context[binding.expression](el);
    });
    el.observer.observe(el);
  },
  unbind: (el) => {
    el.observer.unobserve(el);
  },
});

Vue.directive("observe-width", {
  bind: (el, binding, vnode) => {
    let prevWidth = 0;
    el.observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const width = entry.borderBoxSize?.[0].inlineSize;
        if (typeof width === "number" && width !== prevWidth) {
          prevWidth = width;
          vnode.context[binding.expression](el);
        }
      }
      vnode.context[binding.expression](el);
    });
    el.observer.observe(el);
  },
  unbind: (el) => {
    el.observer.unobserve(el);
  },
});
