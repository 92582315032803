import type { Commit, Dispatch } from "vuex";
import { HttpClient, RequestConfigParams, uuid4 } from "~/types/types";

import urlBuilder from "~/assets/js/urlBuilder";
import { FeedbackProvider } from "~/types/feedback/feedback";

export default class DownloadService {
  static async prepareDownload(
    httpClient: HttpClient,
    endpoint: string,
    requestParams: RequestConfigParams,
    context: { dispatch: Dispatch; commit: Commit; $feedback: FeedbackProvider },
  ) {
    const { dispatch, commit, $feedback } = context;

    const apiUrl = urlBuilder(endpoint, requestParams);

    try {
      await httpClient.get(apiUrl);
      await dispatch("application/setDownloads", {}, { root: true });
      commit("application/SET_IS_WAITING_FOR_DOWNLOADS", true, { root: true });
      $feedback.ok("generic.download.ok");
    } catch (error) {
      $feedback.error("generic.download.error");
    }
  }
  static async downloadFile(
    httpClient: HttpClient,
    id: uuid4,
    filename: string,
    context: { commit: Commit; $feedback: FeedbackProvider },
  ) {
    const { commit, $feedback } = context;

    const apiUrl = `tasks/${id}`;

    try {
      const response = await httpClient.get(apiUrl, {
        responseType: "blob",
      });
      const fileBlob: Blob = response.data;
      const url = window.URL.createObjectURL(new Blob([fileBlob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      commit("REMOVE_DOWNLOAD", id);
    } catch (error) {
      $feedback.error(error.message);
    }
  }
  static async downloadDocumentation(httpClient: HttpClient) {
    try {
      const response = await httpClient.get("download", { responseType: "blob" });
      const fileBlob: Blob = response.data;

      // eslint-disable-next-line no-inner-declarations
      function buildLink(url: string) {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "microclaudia - manual de usuario.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      if (response.data.type === "application/pdf") {
        const url = window.URL.createObjectURL(new Blob([fileBlob]));
        // build url
        buildLink(url);
      } else {
        const blob = new Blob([fileBlob], { type: "application/json" });
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          const res = e.target.result as string;
          const { url } = JSON.parse(res);
          // build url
          buildLink(url);
        };
        fileReader.readAsText(blob);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
}
