module.exports = {
  back: {
    DEFAULT: "#D9D9D9",
    dark: "#B4B4B4",
    darkest: "#535353",
    light: "#F1F1F1",
    lightest: "#FFFFFF",
  },
  primary: {
    DEFAULT: "#0078AB",
    dark: "#0A608C",
    darkest: "#073147",
    light: "#73D5FE",
    lightest: "#D0EFFD",
  },
  status: {
    active: {
      DEFAULT: "#ABC957",
      dark: "#738F23",
      light: "#DAECA8",
      lightest: "#EDFBC8",
    },
    error: {
      DEFAULT: "#F95273",
      dark: "#8A2438",
      light: "#FDA9B9",
      lightest: "#FDE4E9",
    },
    ok: {
      DEFAULT: "#60C957",
      dark: "#258C1C",
      light: "#A8ECB7",
      lightest: "#D2FFDC",
    },
    warning: {
      DEFAULT: "#F2BC48",
      dark: "#926E22",
      light: "#FFDB8C",
      lightest: "#FFF1D3",
    },
  },
};
