import { UserAgencyDTO, UserDTO, UserRoleDTO, UserSaveDTO } from "types/user/user";
import type { ActionTree, MutationTree } from "vuex";
import { PaginatedResponse, RequestConfigParams, uuid4 } from "~/types/types";
import {
  ADD_USER,
  DELETE_USER,
  SET_AGENCIES,
  SET_EDITING_USER,
  SET_INSTANCE_ID,
  SET_USER,
  SET_USERS,
  UPDATE_ROLES,
} from "./usersMutationTypes";

import urlBuilder from "~/assets/js/urlBuilder";
import DownloadService from "~/services/DownloadService";
import { SET_LOADING } from "../sharedMutationTypes";

export const state = () => ({
  allAgencies: null as UserAgencyDTO[],
  users: null as PaginatedResponse<UserDTO>,
  loading: false,
  instanceId: null as uuid4,
  allRoles: null as UserRoleDTO[],
  editingUser: null as UserDTO,
});

type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  [ADD_USER](state, user: UserDTO) {
    state.users.collection.unshift(user);
  },

  [DELETE_USER](state, id: uuid4) {
    const index = state.users.collection.findIndex((user) => user.id === id);
    const isUserFound = index >= 0;

    if (isUserFound) {
      state.users.collection.splice(index, 1);
    }
  },

  [SET_AGENCIES](state, agencies) {
    state.allAgencies = agencies;
  },

  [SET_USERS](state, users: PaginatedResponse<UserDTO>) {
    state.users = users;
  },

  [SET_USER](state, updatedUser: UserDTO) {
    const index = state.users.collection.findIndex((user) => user.id === updatedUser.id);

    if (index >= 0) {
      state.users.collection.splice(index, 1, updatedUser);
    }
  },

  [UPDATE_ROLES](state, roles: UserRoleDTO[]) {
    state.allRoles = roles;
  },

  [SET_INSTANCE_ID](state, instanceId: uuid4 /* ??? */) {
    state.instanceId = instanceId;
  },

  [SET_EDITING_USER](state, editingUser: UserDTO) {
    state.editingUser = editingUser;
  },

  [SET_LOADING](state: RootState, loading: boolean) {
    state.loading = loading;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async addUser({ commit }, user: UserDTO) {
    try {
      const createdUser = await this.$axios.$post<UserDTO>("users", user);
      commit(ADD_USER, createdUser);
      this.$feedback.ok("feedback.create.user.ok");
    } catch (error) {
      const { error: prefix, message } = error.response.data;
      this.$feedback.error(`${prefix}${message}`);
    }
  },

  async deleteUser({ commit }, id: uuid4) {
    try {
      await this.$axios.delete(`users/${id}`);
      commit(DELETE_USER, id);
      this.$feedback.ok("feedback.delete.user.ok");
    } catch (error) {
      this.$feedback.error("feedback.delete.user.error");
    }
  },

  async fetch({ dispatch, commit, rootGetters }, args) {
    commit(SET_LOADING, true);
    try {
      const { instanceId = rootGetters["application/getMainInstanceId"] } = args;
      commit("SET_INSTANCE_ID", instanceId);
      await dispatch("setUsers");
      await dispatch("setAgencies");
      await dispatch("setRoles");
    } finally {
      commit(SET_LOADING, false);
    }
  },

  async setAgencies({ commit, state }) {
    const { instanceId } = state;
    try {
      const agencies = await this.$axios.$get<PaginatedResponse<UserAgencyDTO>>(
        `instances/${instanceId}/agencies/all?size=10000`,
      );
      commit(SET_AGENCIES, agencies.collection);
    } catch (error) {
      throw new Error(error);
    }
  },

  async setRoles({ commit }) {
    try {
      const roles = await this.$axios.$get<UserRoleDTO[]>("/users/roles");
      commit(UPDATE_ROLES, roles);
    } catch (error) {
      throw new Error(error);
    }
  },

  async setUser({ commit }, { savedUser, id }: { savedUser: UserSaveDTO; id: uuid4 }) {
    try {
      const user = await this.$axios.$patch<UserDTO>(`users/${id}`, savedUser);
      commit(SET_USER, user);
      this.$feedback.ok("feedback.update.user.ok");
    } catch (error) {
      this.$feedback.error("feedback.update.user.error");
    }
  },

  async setUsers({ commit, rootGetters }, requestParams: RequestConfigParams = {}) {
    const { page, sortBy, orderBy, filter } = requestParams;

    try {
      const size = rootGetters["application/getPageSize"];

      const apiUrl = urlBuilder("users", {
        size,
        page,
        sortBy,
        orderBy,
        filter,
      });

      const users = await this.$axios.$get<PaginatedResponse<UserDTO>>(apiUrl);
      commit(SET_USERS, users);
    } catch (error) {
      throw new Error(error);
    }
  },

  async fetchUser({ commit }, id: uuid4) {
    try {
      const editingUser = await this.$axios.$get<UserDTO>(`users/${id}`);
      commit(SET_EDITING_USER, editingUser);
    } catch (error) {
      throw new Error(error);
    }
  },

  async prepareCsv({ commit, dispatch }, requestParams: RequestConfigParams) {
    const endpoint = `/users/preparecsv`;

    await DownloadService.prepareDownload(this.$axios, endpoint, requestParams, {
      dispatch,
      commit,
      $feedback: this.$feedback,
    });
  },
};
