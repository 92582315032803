export const SET_COMPUTERS = "SET_COMPUTERS";
export const SET_COMPUTER = "SET_COMPUTER";
export const DELETE_COMPUTER = "DELETE_COMPUTER";
export const DELETE_ALL_COMPUTERS = "DELETE_ALL_COMPUTERS";
export const SET_AGENCY = "SET_AGENCY";
export const SET_TAGS = "SET_TAGS";
export const MARK_FOR_REMOVAL = "MARK_FOR_REMOVAL";
export const REMOVE_SELECTED_COMPUTERS = "REMOVE_SELECTED_COMPUTERS";
export const UNINSTALL_SELECTED_COMPUTERS = "UNINSTALL_SELECTED_COMPUTERS";
export const SET_CURRENT_COMPUTER = "SET_CURRENT_COMPUTER";
export const RESET_CURRENT_COMPUTER = "RESET_CURRENT_COMPUTER";
export const SET_DEPLOYMENT_STATUS = "SET_DEPLOYMENT_STATUS";
export const REMOVE_VACCINE_DEPLOYMENTS = "REMOVE_VACCINE_DEPLOYMENTS";
export const SET_VACCINE_DEPLOYMENTS = "SET_VACCINE_DEPLOYMENTS";
export const REMOVE_ALL_VACCINE_DEPLOYMENTS = "REMOVE_ALL_VACCINE_DEPLOYMENTS";
export const SET_VACCINATED_STATUS = "SET_VACCINATED_STATUS";
export const SET_DEPLOYMENT_VACCINATED_STATUS = "SET_DEPLOYMENT_VACCINATED_STATUS";
