var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"relative w-16 h-full bg-primary flex flex-col items-center py-8 flex-shrink-0"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"flex-grow flex flex-col items-center justify-end"},_vm._l((_vm.links),function(ref,index){
var icon = ref.icon;
var popover = ref.popover;
var component = ref.component;
var eventHandler = ref.eventHandler;
var visible = ref.visible;
var button = ref.button;
var action = ref.action;
var classNames = ref.classNames;
return _c('aside-link',{key:index,class:[classNames, { 'mt-3': index }],attrs:{"visible":visible,"icon":icon,"popover":popover,"button":button,"action":action},on:{"link-clicked":function($event){return _vm.onAsideLinkClick(eventHandler)}}},[(popover)?_c(component,{tag:"component"}):_vm._e()],1)}),1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"w-10 flex justify-center items-center"},[_c('img',{staticClass:"w-full",attrs:{"src":require("assets/images/logo.png"),"alt":"Logo"}})])])}]

export { render, staticRenderFns }