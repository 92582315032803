import type { ActionTree, MutationTree } from "vuex";
import { PaginatedResponse, RequestConfigParams } from "~/types/types";
import { SET_AUDIT_ENTRIES, SET_VIEWING_AUDIT } from "./auditMutationTypes";

import urlBuilder from "~/assets/js/urlBuilder";
import DownloadService from "~/services/DownloadService";
import { AuditDTO } from "~/types/audit/audit";
import { SET_LOADING } from "../sharedMutationTypes";

export const state = () => ({
  auditEntries: null as PaginatedResponse<AuditDTO>,
  loading: false,
  viewingAudit: null as AuditDTO,
});

type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  [SET_AUDIT_ENTRIES](state: RootState, args: PaginatedResponse<AuditDTO>) {
    state.auditEntries = args;
  },

  [SET_LOADING](state: RootState, loading: boolean) {
    state.loading = loading;
  },

  [SET_VIEWING_AUDIT](state, viewingAudit: AuditDTO) {
    state.viewingAudit = viewingAudit;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async fetch({ dispatch, commit }, args) {
    commit(SET_LOADING, true);
    try {
      await dispatch("setAudits", args);
    } finally {
      commit(SET_LOADING, false);
    }
  },

  async setAudits({ commit, rootGetters }, requestParams: RequestConfigParams = {}) {
    const { sortBy, orderBy, page, filter } = requestParams;
    const size = rootGetters["application/getPageSize"];

    const apiUrl = urlBuilder("audit", {
      size,
      page,
      sortBy,
      orderBy,
      filter,
    });

    try {
      const audits: PaginatedResponse<AuditDTO> = await this.$axios.$get(apiUrl);
      commit(SET_AUDIT_ENTRIES, audits);
    } catch (error) {
      throw new Error(error);
    }
  },

  async fetchAudit({ commit }, uuid) {
    try {
      const audit = await this.$axios.$get<AuditDTO>(`audit/${uuid}`);
      commit(SET_VIEWING_AUDIT, audit);
    } catch (error) {
      throw new Error(error);
    }
  },

  async prepareCsv({ commit, dispatch }, requestParams: RequestConfigParams = {}) {
    const endpoint = `/audit/preparecsv`;

    await DownloadService.prepareDownload(this.$axios, endpoint, requestParams, {
      dispatch,
      commit,
      $feedback: this.$feedback,
    });
  },
};
