import { render, staticRenderFns } from "./TechSupportForm.vue?vue&type=template&id=cb3fe9bc&"
import script from "./TechSupportForm.vue?vue&type=script&lang=js&"
export * from "./TechSupportForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {S2InputText: require('/usr/src/app/node_modules/front-components/components/ui/form/InputText.vue').default,LabeledInput: require('/usr/src/app/components/form/LabeledInput.vue').default,S2InputTags: require('/usr/src/app/node_modules/front-components/components/ui/form/InputTags.vue').default,S2InputSelect: require('/usr/src/app/node_modules/front-components/components/ui/form/InputSelect.vue').default,S2InputTextarea: require('/usr/src/app/node_modules/front-components/components/ui/form/InputTextarea.vue').default,FormFooter: require('/usr/src/app/components/panel/FormFooter.vue').default})
