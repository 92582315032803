// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ({ store, redirect, route }) {
  const cancelToken = store.getters["application/getCancelToken"];

  if (cancelToken) cancelToken.cancel();
  store.dispatch("application/renewCancelToken");
  if (!store.getters["authentication/isAuthorizated"]) {
    return redirect("/login");
  }
}
