import { Plugin } from "@nuxt/types";
import { v4 as uuidv4 } from "uuid";
import Vue from "vue";

import { FEEDBACK } from "~/assets/js/constants";
import { FeedbackProvider } from "~/types/feedback/feedback";

// Declaration for store
declare module "vuex/types/index" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $feedback: FeedbackProvider;
  }
}

// Declaration for components
declare module "vue/types/vue" {
  interface Vue {
    $feedback: FeedbackProvider;
  }
}

const feedbackPlugin: Plugin = (context, inject) => {
  const feedback = Vue.observable({
    messages: [],

    ok(text: string) {
      const status = FEEDBACK.OK;
      feedback.messages.push({ id: uuidv4(), text, status });
    },

    warning(text: string) {
      const status = FEEDBACK.ALERT;
      feedback.messages.push({ id: uuidv4(), text, status });
    },

    error(text: string) {
      const status = FEEDBACK.ERROR;
      feedback.messages.push({ id: uuidv4(), text, status });
    },

    removeMessage(id: string) {
      const index = feedback.messages.findIndex((message) => message.id === id);
      const isFound = index !== -1;
      if (isFound) {
        feedback.messages.splice(index, 1);
      }
    },
    getMessages() {
      return feedback.messages;
    },
  });

  inject("feedback", feedback);
};

export default feedbackPlugin;
