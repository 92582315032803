import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b5277214 = () => interopDefault(import('../pages/agencies/index.vue' /* webpackChunkName: "pages/agencies/index" */))
const _6241272a = () => interopDefault(import('../pages/alerts/index.vue' /* webpackChunkName: "pages/alerts/index" */))
const _1d455004 = () => interopDefault(import('../pages/audit/index.vue' /* webpackChunkName: "pages/audit/index" */))
const _521f55f2 = () => interopDefault(import('../pages/certs/index.vue' /* webpackChunkName: "pages/certs/index" */))
const _6810ad00 = () => interopDefault(import('../pages/code/index.vue' /* webpackChunkName: "pages/code/index" */))
const _b37723d6 = () => interopDefault(import('../pages/config/index.vue' /* webpackChunkName: "pages/config/index" */))
const _e9f249a0 = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _5a3012e7 = () => interopDefault(import('../pages/federated/index.vue' /* webpackChunkName: "pages/federated/index" */))
const _5e75dbe8 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _fba9bb34 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _87cedfa0 = () => interopDefault(import('../pages/renew/index.vue' /* webpackChunkName: "pages/renew/index" */))
const _7fc67e5c = () => interopDefault(import('../pages/telemetry/index.vue' /* webpackChunkName: "pages/telemetry/index" */))
const _5cb9942a = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _35992d31 = () => interopDefault(import('../pages/vaccines/index.vue' /* webpackChunkName: "pages/vaccines/index" */))
const _efa1b96a = () => interopDefault(import('../pages/version-control/index.vue' /* webpackChunkName: "pages/version-control/index" */))
const _b3c0886a = () => interopDefault(import('../pages/agencies/_agency/index.vue' /* webpackChunkName: "pages/agencies/_agency/index" */))
const _72cb10f2 = () => interopDefault(import('../pages/federated/_instanceId/index.vue' /* webpackChunkName: "pages/federated/_instanceId/index" */))
const _75c4c071 = () => interopDefault(import('../pages/agencies/_agency/alerts/index.vue' /* webpackChunkName: "pages/agencies/_agency/alerts/index" */))
const _0b9c4c18 = () => interopDefault(import('../pages/agencies/_agency/computers/index.vue' /* webpackChunkName: "pages/agencies/_agency/computers/index" */))
const _5f108bb5 = () => interopDefault(import('../pages/agencies/_agency/telemetry/index.vue' /* webpackChunkName: "pages/agencies/_agency/telemetry/index" */))
const _769b90b8 = () => interopDefault(import('../pages/agencies/_agency/vaccines/index.vue' /* webpackChunkName: "pages/agencies/_agency/vaccines/index" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'hash',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agencies",
    component: _b5277214,
    name: "agencies"
  }, {
    path: "/alerts",
    component: _6241272a,
    name: "alerts"
  }, {
    path: "/audit",
    component: _1d455004,
    name: "audit"
  }, {
    path: "/certs",
    component: _521f55f2,
    name: "certs"
  }, {
    path: "/code",
    component: _6810ad00,
    name: "code"
  }, {
    path: "/config",
    component: _b37723d6,
    name: "config"
  }, {
    path: "/faqs",
    component: _e9f249a0,
    name: "faqs"
  }, {
    path: "/federated",
    component: _5a3012e7,
    name: "federated"
  }, {
    path: "/login",
    component: _5e75dbe8,
    name: "login"
  }, {
    path: "/news",
    component: _fba9bb34,
    name: "news"
  }, {
    path: "/renew",
    component: _87cedfa0,
    name: "renew"
  }, {
    path: "/telemetry",
    component: _7fc67e5c,
    name: "telemetry"
  }, {
    path: "/users",
    component: _5cb9942a,
    name: "users"
  }, {
    path: "/vaccines",
    component: _35992d31,
    name: "vaccines"
  }, {
    path: "/version-control",
    component: _efa1b96a,
    name: "version-control"
  }, {
    path: "/agencies/:agency",
    component: _b3c0886a,
    name: "agencies-agency"
  }, {
    path: "/federated/:instanceId",
    component: _72cb10f2,
    name: "federated-instanceId"
  }, {
    path: "/agencies/:agency/alerts",
    component: _75c4c071,
    name: "agencies-agency-alerts"
  }, {
    path: "/agencies/:agency/computers",
    component: _0b9c4c18,
    name: "agencies-agency-computers"
  }, {
    path: "/agencies/:agency/telemetry",
    component: _5f108bb5,
    name: "agencies-agency-telemetry"
  }, {
    path: "/agencies/:agency/vaccines",
    component: _769b90b8,
    name: "agencies-agency-vaccines"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
