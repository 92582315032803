import { InputSelectOption } from "~/types/types";

function mapFn(item: unknown) {
  return { id: item, label: item };
}

export default class OptionsTransformer {
  static options<T>(items?: T[], mapFunc = mapFn): InputSelectOption[] {
    if (items && items.length > 0) {
      return items.map(mapFunc) as InputSelectOption[];
    }
    return [];
  }
}
