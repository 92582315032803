//
//
//
//
//
//
//
//

export default {
  name: "SecondaryNavLink",
  props: {
    // eslint-disable-next-line vue/require-default-prop
    agencyId: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  computed: {
    target() {
      return `/agencies/${this.agencyId}/${this.link}`;
    },
  },
};
