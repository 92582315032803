import { LIFECYCLE_STATE } from "../constants";

export function lifecycleToLifecycle(lifecycle: LIFECYCLE_STATE) {
  switch (lifecycle) {
    case LIFECYCLE_STATE.NOT_APPLIED_REQUIRED:
      return LIFECYCLE_STATE.PENDING;

    case LIFECYCLE_STATE.NOT_APPLIED_NOT_REQUIRED:
      return LIFECYCLE_STATE.PENDING;

    case LIFECYCLE_STATE.APPLIED:
    case LIFECYCLE_STATE.APPLIED_REQUIRED_LEGACY:
    case LIFECYCLE_STATE.APPLIED_NOT_REQUIRED_LEGACY:
    case LIFECYCLE_STATE.APPLIED_WITH_ERROR:
    case LIFECYCLE_STATE.APPLIED_WITH_ERROR_REQUIRED_LEGACY:
    case LIFECYCLE_STATE.APPLIED_WITH_ERROR_NOT_REQUIRED_LEGACY:
      return LIFECYCLE_STATE.PENDING;

    case LIFECYCLE_STATE.PENDING:
    case LIFECYCLE_STATE.PENDING_REQUIRED_LEGACY:
    case LIFECYCLE_STATE.PENDING_NOT_REQUIRED_LEGACY:
      return LIFECYCLE_STATE.NOT_APPLIED_REQUIRED;
  }
}
