//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "FeedbackMessage",
  props: {
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    lifetime: {
      type: Number,
      default: 5000,
    },
    values: {
      type: Array,
      default: () => [],
    },
    status: {
      type: Number,
      required: true,
      validator: (value) => [1, 2, 3].includes(value),
    },
  },
  mounted() {
    setTimeout(() => {
      this.removeMessage();
    }, this.lifetime);
  },
  methods: {
    removeMessage() {
      this.$feedback.removeMessage(this.id);
    },
  },
};
