import { INSTANCE_TYPE } from "~/assets/js/constants";
import urlBuilder from "~/assets/js/urlBuilder";
import { InstanceDTO, PaginatedResponse, RequestConfigParams, uuid4 } from "~/types/types";
import type { ActionTree, MutationTree } from "vuex";
import {
  ADD_INSTANCE,
  DELETE_INSTANCE,
  SET_EDITING_INSTANCE,
  SET_INSTANCE,
  SET_INSTANCES,
} from "./instancesMutationTypes";
import { SET_LOADING } from "../sharedMutationTypes";

export const state = () => ({
  instances: null as PaginatedResponse<InstanceDTO>,
  editingInstance: null as InstanceDTO,
  loading: false,
});

type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  [SET_INSTANCES](state: RootState, instances: PaginatedResponse<InstanceDTO>) {
    state.instances = instances;
  },

  [SET_INSTANCE](state: RootState, updatedInstance: InstanceDTO) {
    const index = state.instances.collection.findIndex(
      (config) => config.id === updatedInstance.id,
    );
    const isInstanceFound = index >= 0;

    if (isInstanceFound) {
      state.instances.collection.splice(index, 1, updatedInstance);
    }
  },

  [ADD_INSTANCE](state: RootState, instance: InstanceDTO) {
    state.instances.collection.unshift(instance);
  },

  [DELETE_INSTANCE](state, id) {
    const index = state.instances.collection.findIndex((instance) => instance.id === id);
    const isInstanceFound = index >= 0;

    if (isInstanceFound) {
      state.instances.collection.splice(index, 1);
    }
  },

  [SET_EDITING_INSTANCE](state, editingInstance: InstanceDTO) {
    state.editingInstance = editingInstance;
  },

  [SET_LOADING](state: RootState, loading: boolean) {
    state.loading = loading;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async fetch({ dispatch, commit }, requestParams: RequestConfigParams) {
    commit(SET_LOADING, true);
    try {
      await dispatch("setInstances", requestParams);
    } finally {
      commit(SET_LOADING, false);
    }
  },

  async setInstances({ commit, dispatch, rootGetters }, requestParams: RequestConfigParams = {}) {
    const defaultFilter = `type:${INSTANCE_TYPE.FEDERATED}`;
    const { sortBy, orderBy, page, filter } = requestParams;
    const size = rootGetters["application/getPageSize"];

    dispatch("application/renewCancelToken", {}, { root: true });

    const apiUrl = urlBuilder("instances", {
      size,
      page,
      sortBy,
      orderBy,
      filter,
      defaultFilter,
    });

    try {
      const instances = await this.$axios.$get<PaginatedResponse<InstanceDTO>>(apiUrl);
      commit(SET_INSTANCES, instances);
    } catch (error) {
      throw new Error(error);
    }
  },

  async setInstance(
    { commit, dispatch },
    { savedInstance, id }: { savedInstance: InstanceDTO; id: uuid4 },
  ) {
    try {
      const instance = await this.$axios.$patch<InstanceDTO>(`instances/${id}`, savedInstance);

      commit(SET_INSTANCE, instance);
      dispatch("application/updateFederatedNavLink", instance, { root: true });
      this.$feedback.ok("feedback.update.instance.ok");
    } catch (error) {
      this.$feedback.error("feedback.update.instance.error");
    }
  },

  async addInstance({ commit, dispatch }, instance: InstanceDTO) {
    try {
      const addedInstance = await this.$axios.$post<InstanceDTO>("instances", instance);

      commit(ADD_INSTANCE, addedInstance);
      dispatch("application/addFederatedNavLink", addedInstance, { root: true });

      this.$feedback.ok("feedback.create.instance.ok");
    } catch (error) {
      const { error: prefix, message } = error.response.data;
      this.$feedback.error(`${prefix}${message}`);
    }
  },

  async deleteInstance({ commit, dispatch }, id: uuid4) {
    try {
      await this.$axios.delete(`instances/${id}`);
      commit(DELETE_INSTANCE, id);
      dispatch("application/removeFederatedNavLink", id, { root: true });
      this.$feedback.ok("feedback.delete.instance.ok");
    } catch (error) {
      this.$feedback.error("feedback.delete.instance.error");
    }
  },

  async fetchInstance({ commit }, id: uuid4) {
    try {
      const editingInstance = await this.$axios.$get<InstanceDTO>(`instances/${id}`);
      commit(SET_EDITING_INSTANCE, editingInstance);
    } catch (error) {
      throw new Error(error);
    }
  },
};
