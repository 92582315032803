export const SET_TYPES = "SET_TYPES";
export const SET_OSES = "SET_OSES";
export const SET_VACCINES = "SET_VACCINES";
export const SET_VACCINE_LIST = "SET_VACCINE_LIST";
export const SET_VACCINE = "SET_VACCINE";
export const ADD_VACCINE = "ADD_VACCINE";
export const DELETE_VACCINE = "DELETE_VACCINE";
export const SET_EDITING_VACCINE = "SET_EDITING_VACCINE";
export const SET_AGENCY_DEPLOYMENTS = "SET_AGENCY_DEPLOYMENTS";
export const REMOVE_AGENCY_DEPLOYMENTS = "REMOVE_AGENCY_DEPLOYMENTS";
export const SET_AGENT_DEPLOYMENTS = "SET_AGENT_DEPLOYMENTS";
export const REMOVE_AGENT_DEPLOYMENTS = "REMOVE_AGENT_DEPLOYMENTS";
export const SET_AGENT_DEPLOYMENT_STATUS = "SET_AGENT_DEPLOYMENT_STATUS";
export const SET_AGENCY_DEPLOYMENT_STATUS = "SET_AGENCY_DEPLOYMENT_STATUS";
export const SET_AGENT_DEPLOYMENT_STATUS_AUTORUN = "SET_AGENT_DEPLOYMENT_STATUS_AUTORUN";
export const SET_AGENCY_DEPLOYMENT_STATUS_AUTORUN = "SET_AGENCY_DEPLOYMENT_STATUS_AUTORUN";
export const SET_VACCINE_STATUS_AUTORUN = "SET_VACCINE_STATUS_AUTORUN";
export const REMOVE_ALL_AGENT_DEPLOYMENTS = "REMOVE_ALL_AGENT_DEPLOYMENTS";
export const REMOVE_ALL_AGENCY_DEPLOYMENTS = "REMOVE_ALL_AGENCY_DEPLOYMENTS";
