export const SET_ALERTS = "SET_ALERTS";
export const SET_ALERT = "SET_ALERT";
export const SET_NEWS = "SET_NEWS";
export const SET_CIRCLES = "SET_CIRCLES";
export const SET_DOUGHNUTS = "SET_DOUGHNUTS";
export const SET_LOADING = "SET_LOADING";
export const SET_PYRAMIDS = "SET_PYRAMIDS";
export const SET_COMPUTER_EVOLUTION_STATS = "SET_COMPUTER_EVOLUTION_STATS";
export const SET_ALERT_EVOLUTION_STATS = "SET_ALERT_EVOLUTION_STATS";
export const SET_ALERTS_PER_DAY_EVOLUTION_STATS = "SET_ALERTS_PER_DAY_EVOLUTION_STATS";
export const SET_AGENTS_PER_DAY_EVOLUTION_STATS = "SET_AGENTS_PER_DAY_EVOLUTION_STATS";
export const SET_ALERTS_PER_VACCINE_EVOLUTION_STATS = "SET_ALERTS_PER_VACCINE_EVOLUTION_STATS";
export const SET_VERSIONS_PER_AGENT_EVOLUTION_STATS = "SET_VERSIONS_PER_AGENT_EVOLUTION_STATS";
export const SET_VACCINE_EVOLUTION_STATS = "SET_VACCINE_EVOLUTION_STATS";
export const SET_AGENCY_EVOLUTION_STATS = "SET_AGENCY_EVOLUTION_STATS";
export const SET_IS_STATS_EVOLUTION_ERROR = "SET_IS_STATS_EVOLUTION_ERROR";
