//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { capitalize } from "~/assets/js/stringUtils";

export default {
  name: "NavLink",
  props: {
    closable: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconClasses: {
      type: String,
      default: "",
    },
    link: {
      type: [String, Function],
      default: "",
    },
    textLink: {
      type: String,
      required: true,
    },
    touched: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      capitalize,
    };
  },
};
