import { differenceInMilliseconds } from "date-fns";
import jwtDecode from "jwt-decode";

/**
 * If the difference between the expiration date and the current date is less than or equal to 0, then
 * the token is expired.
 * @param token - The token to check
 * @returns The difference in milliseconds between the expiration date and the current date.
 */
export function isTokenExpired(token) {
  if (!token) {
    return true;
  }
  const decodedToken = jwtDecode(token);

  const expirationDate = decodedToken.exp * 1000;
  const now = new Date().getTime();
  const expiration = differenceInMilliseconds(expirationDate, now);

  return expiration <= 0;
}
