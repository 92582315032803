//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FeedbackMessage from "~/components/feedback/FeedbackMessage.vue";

export default {
  name: "FeedbackComponent",
  components: {
    FeedbackMessage,
  },
  methods: {
    /* istanbul ignore next */
    messageEnter(el) {
      el.style.marginBottom = `-${el.offsetHeight}px`;
      this.$velocity(
        el,
        { marginBottom: "0px" },
        {
          duration: 250,
          easing: "ease-in",
          complete: () => {
            if (el.style.removeProperty) {
              el.style.removeProperty("margin-bottom");
            } else {
              el.style.removeAttribute("margin-bottom");
            }
          },
        },
      );
    },
    /* istanbul ignore next */
    messageLeave(el, done) {
      this.$velocity(
        el,
        { opacity: 0 },
        {
          duration: 250,
          easing: "ease-in",
          complete: done,
        },
      );
    },
  },
};
