import { uuid4 } from "~/types/types";
import OptionsTransformer from "./OptionsTransformer";
import { IssueTypeDTO } from "~/types/issue/issue";

function idAndNameMapFn(item: { id: uuid4; name: string }) {
  return { id: `${item.id}`, label: item.name };
}

export default class IssuesTransformer {
  static issueTypeOptions(issueTypes: IssueTypeDTO[], i18n) {
    if (!issueTypes) {
      return [];
    }
    const translatedIssueTypes = issueTypes.map((issueType) => {
      return {
        ...issueType,
        name: i18n.t(`techSupport.edit.issueType.${issueType.name.toLowerCase()}`),
      };
    });

    return OptionsTransformer.options<IssueTypeDTO>(translatedIssueTypes, idAndNameMapFn);
  }
}
