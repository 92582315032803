//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SecondaryNavLink from "~/components/nav/secondary/SecondaryNavLink.vue";

export default {
  name: "SecondaryNav",
  components: {
    SecondaryNavLink,
  },
  props: {
    agency: {
      type: Object,
      required: true,
    },

    secondaryNavLinks: {
      type: Array,
      required: true,
    },
  },
};
