// const COLORS = require("./assets/theme/colors");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const DEFAULT_THEME = require("tailwindcss/defaultTheme");

module.exports = {
  theme: {
    extend: {
      backgroundImage: {
        "gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
      },
      maxHeight: {
        4: "1rem",
        27: "6.75rem",
        76: "19rem",
        84: "21rem",
      },
      maxWidth: {
        4: "1rem",
        "8xl": "88rem",
        "screen-half": "50vw",
      },
      minHeight: {
        36: "9rem",
      },
      minWidth: {
        3: "0.75rem",
        5: "1.25rem",
        16: "4rem",
        24: "6rem",
        32: "8rem",
        56: "14rem",
        64: "16rem",
        88: "22rem",
        120: "30rem",
        144: "36rem",
      },
      zIndex: {
        70: 70,
      },
      width: {
        192: "54rem",
        76: "19rem",
      },
      spacing: {
        full: "100%",
        "2ch": "2ch",
        "4ch": "4ch",
        1.5: "0.375rem",
        18: "4.5rem",
        22: "5.5rem",
        47: "11.75rem",
        83: "20.75rem",
        88: "22rem",
        90: "22.5rem",
        120: "30rem",
        126: "31.5rem",
        144: "36rem",
        157: "39.25rem",
      },
      transitionDelay: {
        0: "0s",
      },
      transitionProperty: {
        color: "color",
      },
    },
    borderRadius: {
      none: "0px",
      sm: "0.13rem",
      md: "0.25rem",
      lg: "0.5rem",
      xl: "1rem",
      full: "9999px",
    },
    borderWidth: {
      DEFAULT: "1px",
      0: "0",
      2: "2px",
    },
    colors: {
      back: {
        DEFAULT: "#D9D9D9",
        dark: "#B4B4B4",
        darkest: "#535353",
        light: "#F1F1F1",
        lightest: "#FFFFFF",
      },
      primary: {
        DEFAULT: "#0078AB",
        dark: "#0A608C",
        darkest: "#073147",
        light: "#73D5FE",
        lightest: "#D0EFFD",
      },
      status: {
        active: {
          DEFAULT: "#ABC957",
          dark: "#738F23",
          light: "#DAECA8",
          lightest: "#EDFBC8",
        },
        error: {
          DEFAULT: "#F95273",
          dark: "#8A2438",
          light: "#FDA9B9",
          lightest: "#FDE4E9",
        },
        ok: {
          DEFAULT: "#60C957",
          dark: "#258C1C",
          light: "#A8ECB7",
          lightest: "#D2FFDC",
        },
        warning: {
          DEFAULT: "#F2BC48",
          dark: "#926E22",
          light: "#FFDB8C",
          lightest: "#FFF1D3",
        },
      },
      transparent: "transparent",
    },
    fontFamily: {
      code: "firacode",
      sans: ["roboto", ...DEFAULT_THEME.fontFamily.sans],
      "s2-icons": "s2-icons",
      mc: "mc",
    },
    fontSize: {
      "4xs": "0.38rem",
      "3xs": "0.5rem",
      "2xs": "0.56rem",
      xs: "0.69rem",
      sm: "0.75rem",
      base: "0.81rem",
      lg: "0.94rem",
      xl: "1rem",
      "2xl": "1.13rem",
      "3xl": "1.25rem",
      "4xl": "1.75rem",
    },
    fontWeight: {
      light: "300",
      normal: "400",
      medium: "500",
      bold: "700",
    },
  },
  safelist: [
    {
      pattern: /min-w-(3|5|16|24|32|56|64|88|120|144)/,
    },
    {
      pattern: /w-(3|5|16|24|32|56|64|88|120|144)/,
    },
    {
      pattern: /max-w-screen-half/,
    },
    // {
    //   pattern: /bg-status-active-lightest/,
    // },
  ],
};
