import type { ActionTree, MutationTree } from "vuex";
import urlBuilder from "~/assets/js/urlBuilder";
import { PaginatedResponse, RequestConfigParams, uuid4 } from "~/types/types";
import { ADD_CERT, DELETE_CERT, SET_CERTS, SET_CERTS_LIST } from "./certsMutationTypes";
import { SET_LOADING } from "@/store/sharedMutationTypes";
import { CertDTO, CertSaveDTO } from "~/types/cert/cert";

export const state = () => ({
  certs: null as PaginatedResponse<CertDTO>,
  certsList: null as CertDTO[],
  loading: false,
});

type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  [SET_CERTS](state: RootState, certs: PaginatedResponse<CertDTO>) {
    state.certs = certs;
  },
  [SET_CERTS_LIST](state: RootState, certsList: CertDTO[]) {
    state.certsList = certsList;
  },
  [SET_LOADING](state: RootState, loading: boolean) {
    state.loading = loading;
  },

  [ADD_CERT](state: RootState, cert: CertDTO) {
    state.certs.collection.unshift(cert);
  },

  [DELETE_CERT](state, id: uuid4) {
    const index = state.certs.collection.findIndex((cert) => cert.id === id);
    const isCertFound = index >= 0;
    if (isCertFound) {
      state.certs.collection.splice(index, 1);
    }
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async fetch({ dispatch, commit }, args) {
    commit(SET_LOADING, true);
    try {
      await dispatch("setCerts", args);
    } finally {
      commit(SET_LOADING, false);
    }
  },

  async setCertsList({ commit, rootGetters }, args: RequestConfigParams = {}) {
    const { filter } = args;
    const size = 10000;

    const apiUrl = urlBuilder("certs", {
      size,
      filter,
    });

    try {
      const certs: PaginatedResponse<CertDTO> = await this.$axios.$get(apiUrl, {
        cancelToken: rootGetters["application/getCancelToken"].token,
      });

      commit(SET_CERTS_LIST, certs.collection);
    } catch (error) {
      throw new Error(error);
    }
  },

  async setCerts({ commit, rootGetters }, requestParams: RequestConfigParams = {}) {
    const { sortBy, orderBy, page, filter } = requestParams;

    const size = rootGetters["application/getPageSize"];

    const apiUrl = urlBuilder("certs", {
      size,
      page,
      sortBy,
      orderBy,
      filter,
    });

    try {
      const certs: PaginatedResponse<CertDTO> = await this.$axios.$get(apiUrl);
      commit(SET_CERTS, certs);
    } catch (error) {
      throw new Error(error);
    }
  },

  async addCert({ commit }, savedCert: CertSaveDTO) {
    try {
      const cert = await this.$axios.$post<CertDTO>("certs", { ...savedCert });
      commit(ADD_CERT, cert);
      this.$feedback.ok("feedback.create.cert.ok");
    } catch (error) {
      const { error: prefix, message } = error.response.data;
      this.$feedback.error(`${prefix}${message}`);
    }
  },

  async deleteCert({ commit }, id: uuid4) {
    try {
      await this.$axios.delete(`certs/${id}`);
      commit(DELETE_CERT, id);
      this.$feedback.ok("feedback.delete.cert.ok");
    } catch (error) {
      this.$feedback.error("feedback.delete.cert.error");
    }
  },
};
