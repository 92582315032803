import { AgencyDTO } from "~/types/agency/agency";
import { ConfigDTO } from "~/types/config/config";
import { APIRequest, HttpClient } from "~/types/types";

export default class ConfigService {
  static root = "configurations/name";
  static async getAPIRequestLimits(httpClient: HttpClient): Promise<APIRequest> {
    const maxRequestsConfig: ConfigDTO = (
      await httpClient.get(`${this.root}/AGENCY_REQUEST_MAX_REQUESTS`)
    ).data;

    const maxTokensConfig: ConfigDTO = (
      await httpClient.get(`${this.root}/AGENCY_REQUEST_MAX_TOKENS`)
    ).data;

    const maxRequests = parseInt(maxRequestsConfig.value, 10);
    const maxTokens = parseInt(maxTokensConfig.value, 10);

    return { maxRequests, maxTokens };
  }

  static async getSupportAccount(httpClient: HttpClient, agency: AgencyDTO): Promise<string> {
    if (agency && agency.metadata.supportEmail !== "") {
      return agency.metadata.supportEmail;
    } else {
      const supportAccountConfig: ConfigDTO = (await httpClient.get(`${this.root}/SUPPORT_EMAIL`))
        .data;

      return supportAccountConfig.value;
    }
  }
}
