//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from "vuex";
import NavLink from "~/components/nav/main/NavLink.vue";

export default {
  name: "TheNav",
  components: {
    NavLink,
  },
  props: {
    mainNav: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      transitionDuration: 250,
      transitionDelay: 10,
      open: false,
      fixed: false,
    };
  },
  computed: {
    touched() {
      return this.open || this.fixed;
    },
  },
  methods: {
    ...mapActions({
      removeNavLink: "application/removeNavLink",
      updateVersion: "application/updateVersion",
      setSelectedAgency: "application/setSelectedAgency",
      resetSelectedAgency: "application/resetSelectedAgency",
    }),
    linkEnter(el, done) {
      el.style.marginTop = `-${el.firstChild.offsetHeight}px`;
      this.$velocity(
        el,
        { marginTop: "0px" },
        { duration: this.transitionDuration, easing: "ease-in", complete: done },
      );
    },
    linkLeave(el, done) {
      el.classList.add("relative");
      this.$velocity(
        el,
        { marginTop: `-${el.firstChild.offsetHeight}px` },
        { duration: this.transitionDuration, easing: "ease-in", complete: done },
      );
    },
    updateAgencyData(agency) {
      if (!agency) {
        this.resetSelectedAgency();
      } else {
        this.setSelectedAgency(agency);
      }
    },
    onClick() {
      this.fixed = !this.fixed;
      for (
        let delay = 0;
        delay < this.transitionDuration * 2;
        delay = delay + this.transitionDelay
      ) {
        setTimeout(() => {
          this.updateVersion();
        }, delay);
      }
    },
    async onClose(args) {
      await this.removeNavLink(args);
    },
  },
};
