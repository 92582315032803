export const S2TableCustomizableBodyRowCellActions = () => import('../../node_modules/front-components/components/ui/table/cells/TableCustomizableBodyRowCellActions.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-cell-actions" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRowCellCheck = () => import('../../node_modules/front-components/components/ui/table/cells/TableCustomizableBodyRowCellCheck.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-cell-check" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRowCellDisplay = () => import('../../node_modules/front-components/components/ui/table/cells/TableCustomizableBodyRowCellDisplay.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-cell-display" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRowCellEditable = () => import('../../node_modules/front-components/components/ui/table/cells/TableCustomizableBodyRowCellEditable.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-cell-editable" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRowCellIcon = () => import('../../node_modules/front-components/components/ui/table/cells/TableCustomizableBodyRowCellIcon.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-cell-icon" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRowCellNotification = () => import('../../node_modules/front-components/components/ui/table/cells/TableCustomizableBodyRowCellNotification.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-cell-notification" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRowCellPercentage = () => import('../../node_modules/front-components/components/ui/table/cells/TableCustomizableBodyRowCellPercentage.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-cell-percentage" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRowCellSelect = () => import('../../node_modules/front-components/components/ui/table/cells/TableCustomizableBodyRowCellSelect.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-cell-select" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRowCellSelectButton = () => import('../../node_modules/front-components/components/ui/table/cells/TableCustomizableBodyRowCellSelectButton.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-cell-select-button" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRowCellSelectOptions = () => import('../../node_modules/front-components/components/ui/table/cells/TableCustomizableBodyRowCellSelectOptions.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-cell-select-options" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRowCellStack = () => import('../../node_modules/front-components/components/ui/table/cells/TableCustomizableBodyRowCellStack.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-cell-stack" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRowCellState = () => import('../../node_modules/front-components/components/ui/table/cells/TableCustomizableBodyRowCellState.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-cell-state" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRowCellTags = () => import('../../node_modules/front-components/components/ui/table/cells/TableCustomizableBodyRowCellTags.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-cell-tags" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRowCellText = () => import('../../node_modules/front-components/components/ui/table/cells/TableCustomizableBodyRowCellText.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-cell-text" */).then(c => wrapFunctional(c.default || c))
export const S2BaseDatepicker = () => import('../../node_modules/front-components/components/common/datepickers/BaseDatepicker.vue' /* webpackChunkName: "components/s2-base-datepicker" */).then(c => wrapFunctional(c.default || c))
export const S2BaseModal = () => import('../../node_modules/front-components/components/common/modals/BaseModal.vue' /* webpackChunkName: "components/s2-base-modal" */).then(c => wrapFunctional(c.default || c))
export const S2BaseScrollbar = () => import('../../node_modules/front-components/components/common/scrollbars/BaseScrollbar.vue' /* webpackChunkName: "components/s2-base-scrollbar" */).then(c => wrapFunctional(c.default || c))
export const S2BaseSpinner = () => import('../../node_modules/front-components/components/common/spinners/BaseSpinner.vue' /* webpackChunkName: "components/s2-base-spinner" */).then(c => wrapFunctional(c.default || c))
export const S2BaseContextMenu = () => import('../../node_modules/front-components/components/common/tooltips/BaseContextMenu.vue' /* webpackChunkName: "components/s2-base-context-menu" */).then(c => wrapFunctional(c.default || c))
export const S2BaseTooltip = () => import('../../node_modules/front-components/components/common/tooltips/BaseTooltip.vue' /* webpackChunkName: "components/s2-base-tooltip" */).then(c => wrapFunctional(c.default || c))
export const S2BaseTransitionGroup = () => import('../../node_modules/front-components/components/common/transitions/BaseTransitionGroup.vue' /* webpackChunkName: "components/s2-base-transition-group" */).then(c => wrapFunctional(c.default || c))
export const S2BaseTransitionSimple = () => import('../../node_modules/front-components/components/common/transitions/BaseTransitionSimple.vue' /* webpackChunkName: "components/s2-base-transition-simple" */).then(c => wrapFunctional(c.default || c))
export const S2ButtonInline = () => import('../../node_modules/front-components/components/ui/button/ButtonInline.vue' /* webpackChunkName: "components/s2-button-inline" */).then(c => wrapFunctional(c.default || c))
export const S2InputCheck = () => import('../../node_modules/front-components/components/ui/form/InputCheck.vue' /* webpackChunkName: "components/s2-input-check" */).then(c => wrapFunctional(c.default || c))
export const S2InputDate = () => import('../../node_modules/front-components/components/ui/form/InputDate.vue' /* webpackChunkName: "components/s2-input-date" */).then(c => wrapFunctional(c.default || c))
export const S2InputDateButton = () => import('../../node_modules/front-components/components/ui/form/InputDateButton.vue' /* webpackChunkName: "components/s2-input-date-button" */).then(c => wrapFunctional(c.default || c))
export const S2InputDateOptions = () => import('../../node_modules/front-components/components/ui/form/InputDateOptions.vue' /* webpackChunkName: "components/s2-input-date-options" */).then(c => wrapFunctional(c.default || c))
export const S2InputDateOptionsInputs = () => import('../../node_modules/front-components/components/ui/form/InputDateOptionsInputs.vue' /* webpackChunkName: "components/s2-input-date-options-inputs" */).then(c => wrapFunctional(c.default || c))
export const S2InputDateOptionsInputsChunks = () => import('../../node_modules/front-components/components/ui/form/InputDateOptionsInputsChunks.vue' /* webpackChunkName: "components/s2-input-date-options-inputs-chunks" */).then(c => wrapFunctional(c.default || c))
export const S2InputDateOptionsInputsChunksChunk = () => import('../../node_modules/front-components/components/ui/form/InputDateOptionsInputsChunksChunk.vue' /* webpackChunkName: "components/s2-input-date-options-inputs-chunks-chunk" */).then(c => wrapFunctional(c.default || c))
export const S2InputFile = () => import('../../node_modules/front-components/components/ui/form/InputFile.vue' /* webpackChunkName: "components/s2-input-file" */).then(c => wrapFunctional(c.default || c))
export const S2InputNumber = () => import('../../node_modules/front-components/components/ui/form/InputNumber.vue' /* webpackChunkName: "components/s2-input-number" */).then(c => wrapFunctional(c.default || c))
export const S2InputNumberButtons = () => import('../../node_modules/front-components/components/ui/form/InputNumberButtons.vue' /* webpackChunkName: "components/s2-input-number-buttons" */).then(c => wrapFunctional(c.default || c))
export const S2InputPassword = () => import('../../node_modules/front-components/components/ui/form/InputPassword.vue' /* webpackChunkName: "components/s2-input-password" */).then(c => wrapFunctional(c.default || c))
export const S2InputRadio = () => import('../../node_modules/front-components/components/ui/form/InputRadio.vue' /* webpackChunkName: "components/s2-input-radio" */).then(c => wrapFunctional(c.default || c))
export const S2InputSelect = () => import('../../node_modules/front-components/components/ui/form/InputSelect.vue' /* webpackChunkName: "components/s2-input-select" */).then(c => wrapFunctional(c.default || c))
export const S2InputSelectButton = () => import('../../node_modules/front-components/components/ui/form/InputSelectButton.vue' /* webpackChunkName: "components/s2-input-select-button" */).then(c => wrapFunctional(c.default || c))
export const S2InputSelectOptions = () => import('../../node_modules/front-components/components/ui/form/InputSelectOptions.vue' /* webpackChunkName: "components/s2-input-select-options" */).then(c => wrapFunctional(c.default || c))
export const S2InputSwitch = () => import('../../node_modules/front-components/components/ui/form/InputSwitch.vue' /* webpackChunkName: "components/s2-input-switch" */).then(c => wrapFunctional(c.default || c))
export const S2InputTags = () => import('../../node_modules/front-components/components/ui/form/InputTags.vue' /* webpackChunkName: "components/s2-input-tags" */).then(c => wrapFunctional(c.default || c))
export const S2InputTagsMain = () => import('../../node_modules/front-components/components/ui/form/InputTagsMain.vue' /* webpackChunkName: "components/s2-input-tags-main" */).then(c => wrapFunctional(c.default || c))
export const S2InputTagsMainSearch = () => import('../../node_modules/front-components/components/ui/form/InputTagsMainSearch.vue' /* webpackChunkName: "components/s2-input-tags-main-search" */).then(c => wrapFunctional(c.default || c))
export const S2InputTagsMainSelected = () => import('../../node_modules/front-components/components/ui/form/InputTagsMainSelected.vue' /* webpackChunkName: "components/s2-input-tags-main-selected" */).then(c => wrapFunctional(c.default || c))
export const S2InputTagsOptions = () => import('../../node_modules/front-components/components/ui/form/InputTagsOptions.vue' /* webpackChunkName: "components/s2-input-tags-options" */).then(c => wrapFunctional(c.default || c))
export const S2InputTagsOptionsOption = () => import('../../node_modules/front-components/components/ui/form/InputTagsOptionsOption.vue' /* webpackChunkName: "components/s2-input-tags-options-option" */).then(c => wrapFunctional(c.default || c))
export const S2InputText = () => import('../../node_modules/front-components/components/ui/form/InputText.vue' /* webpackChunkName: "components/s2-input-text" */).then(c => wrapFunctional(c.default || c))
export const S2InputTextarea = () => import('../../node_modules/front-components/components/ui/form/InputTextarea.vue' /* webpackChunkName: "components/s2-input-textarea" */).then(c => wrapFunctional(c.default || c))
export const S2LoginInputPassword = () => import('../../node_modules/front-components/components/ui/form/LoginInputPassword.vue' /* webpackChunkName: "components/s2-login-input-password" */).then(c => wrapFunctional(c.default || c))
export const S2LoginInputText = () => import('../../node_modules/front-components/components/ui/form/LoginInputText.vue' /* webpackChunkName: "components/s2-login-input-text" */).then(c => wrapFunctional(c.default || c))
export const S2EmptyState = () => import('../../node_modules/front-components/components/ui/message/EmptyState.vue' /* webpackChunkName: "components/s2-empty-state" */).then(c => wrapFunctional(c.default || c))
export const S2MessageError = () => import('../../node_modules/front-components/components/ui/message/MessageError.vue' /* webpackChunkName: "components/s2-message-error" */).then(c => wrapFunctional(c.default || c))
export const S2ModalConfirm = () => import('../../node_modules/front-components/components/ui/modal/ModalConfirm.vue' /* webpackChunkName: "components/s2-modal-confirm" */).then(c => wrapFunctional(c.default || c))
export const S2PaginatorExtended = () => import('../../node_modules/front-components/components/ui/paginator/PaginatorExtended.vue' /* webpackChunkName: "components/s2-paginator-extended" */).then(c => wrapFunctional(c.default || c))
export const S2PaginatorExtendedPages = () => import('../../node_modules/front-components/components/ui/paginator/PaginatorExtendedPages.vue' /* webpackChunkName: "components/s2-paginator-extended-pages" */).then(c => wrapFunctional(c.default || c))
export const S2PaginatorExtendedSelect = () => import('../../node_modules/front-components/components/ui/paginator/PaginatorExtendedSelect.vue' /* webpackChunkName: "components/s2-paginator-extended-select" */).then(c => wrapFunctional(c.default || c))
export const S2PaginatorExtendedSelectButton = () => import('../../node_modules/front-components/components/ui/paginator/PaginatorExtendedSelectButton.vue' /* webpackChunkName: "components/s2-paginator-extended-select-button" */).then(c => wrapFunctional(c.default || c))
export const S2PaginatorExtendedSelectOptions = () => import('../../node_modules/front-components/components/ui/paginator/PaginatorExtendedSelectOptions.vue' /* webpackChunkName: "components/s2-paginator-extended-select-options" */).then(c => wrapFunctional(c.default || c))
export const S2SearchEngine = () => import('../../node_modules/front-components/components/ui/search-engine/SearchEngine.vue' /* webpackChunkName: "components/s2-search-engine" */).then(c => wrapFunctional(c.default || c))
export const S2SearchEngineBody = () => import('../../node_modules/front-components/components/ui/search-engine/SearchEngineBody.vue' /* webpackChunkName: "components/s2-search-engine-body" */).then(c => wrapFunctional(c.default || c))
export const S2SearchEngineBodyInput = () => import('../../node_modules/front-components/components/ui/search-engine/SearchEngineBodyInput.vue' /* webpackChunkName: "components/s2-search-engine-body-input" */).then(c => wrapFunctional(c.default || c))
export const S2SearchEngineBodyInputDql = () => import('../../node_modules/front-components/components/ui/search-engine/SearchEngineBodyInputDql.vue' /* webpackChunkName: "components/s2-search-engine-body-input-dql" */).then(c => wrapFunctional(c.default || c))
export const S2SearchEngineBodyInputPlain = () => import('../../node_modules/front-components/components/ui/search-engine/SearchEngineBodyInputPlain.vue' /* webpackChunkName: "components/s2-search-engine-body-input-plain" */).then(c => wrapFunctional(c.default || c))
export const S2SearchEngineBodySelector = () => import('../../node_modules/front-components/components/ui/search-engine/SearchEngineBodySelector.vue' /* webpackChunkName: "components/s2-search-engine-body-selector" */).then(c => wrapFunctional(c.default || c))
export const S2SearchEngineBodyTips = () => import('../../node_modules/front-components/components/ui/search-engine/SearchEngineBodyTips.vue' /* webpackChunkName: "components/s2-search-engine-body-tips" */).then(c => wrapFunctional(c.default || c))
export const S2IconSpinner = () => import('../../node_modules/front-components/components/ui/spinners/IconSpinner.vue' /* webpackChunkName: "components/s2-icon-spinner" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizable = () => import('../../node_modules/front-components/components/ui/table/TableCustomizable.vue' /* webpackChunkName: "components/s2-table-customizable" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBody = () => import('../../node_modules/front-components/components/ui/table/TableCustomizableBody.vue' /* webpackChunkName: "components/s2-table-customizable-body" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRow = () => import('../../node_modules/front-components/components/ui/table/TableCustomizableBodyRow.vue' /* webpackChunkName: "components/s2-table-customizable-body-row" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRowCell = () => import('../../node_modules/front-components/components/ui/table/TableCustomizableBodyRowCell.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-cell" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRowHeader = () => import('../../node_modules/front-components/components/ui/table/TableCustomizableBodyRowHeader.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-header" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRowHeaderMenu = () => import('../../node_modules/front-components/components/ui/table/TableCustomizableBodyRowHeaderMenu.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-header-menu" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableBodyRowHeaderMenuActions = () => import('../../node_modules/front-components/components/ui/table/TableCustomizableBodyRowHeaderMenuActions.vue' /* webpackChunkName: "components/s2-table-customizable-body-row-header-menu-actions" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableFooter = () => import('../../node_modules/front-components/components/ui/table/TableCustomizableFooter.vue' /* webpackChunkName: "components/s2-table-customizable-footer" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableHeader = () => import('../../node_modules/front-components/components/ui/table/TableCustomizableHeader.vue' /* webpackChunkName: "components/s2-table-customizable-header" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableHeaderCell = () => import('../../node_modules/front-components/components/ui/table/TableCustomizableHeaderCell.vue' /* webpackChunkName: "components/s2-table-customizable-header-cell" */).then(c => wrapFunctional(c.default || c))
export const S2TableCustomizableSelectors = () => import('../../node_modules/front-components/components/ui/table/TableCustomizableSelectors.vue' /* webpackChunkName: "components/s2-table-customizable-selectors" */).then(c => wrapFunctional(c.default || c))
export const S2TagInline = () => import('../../node_modules/front-components/components/ui/tag/TagInline.vue' /* webpackChunkName: "components/s2-tag-inline" */).then(c => wrapFunctional(c.default || c))
export const S2WizardCustomizable = () => import('../../node_modules/front-components/components/ui/wizard/WizardCustomizable.vue' /* webpackChunkName: "components/s2-wizard-customizable" */).then(c => wrapFunctional(c.default || c))
export const S2WizardCustomizableAction = () => import('../../node_modules/front-components/components/ui/wizard/WizardCustomizableAction.vue' /* webpackChunkName: "components/s2-wizard-customizable-action" */).then(c => wrapFunctional(c.default || c))
export const S2WizardCustomizableButtons = () => import('../../node_modules/front-components/components/ui/wizard/WizardCustomizableButtons.vue' /* webpackChunkName: "components/s2-wizard-customizable-buttons" */).then(c => wrapFunctional(c.default || c))
export const S2WizardCustomizableSection = () => import('../../node_modules/front-components/components/ui/wizard/WizardCustomizableSection.vue' /* webpackChunkName: "components/s2-wizard-customizable-section" */).then(c => wrapFunctional(c.default || c))
export const S2PageHeader = () => import('../../node_modules/front-components/components/layout/PageHeader.vue' /* webpackChunkName: "components/s2-page-header" */).then(c => wrapFunctional(c.default || c))
export const S2TheHeader = () => import('../../node_modules/front-components/components/layout/TheHeader.vue' /* webpackChunkName: "components/s2-the-header" */).then(c => wrapFunctional(c.default || c))
export const S2TheHeaderNav = () => import('../../node_modules/front-components/components/layout/TheHeaderNav.vue' /* webpackChunkName: "components/s2-the-header-nav" */).then(c => wrapFunctional(c.default || c))
export const S2TheHeaderNavRoute = () => import('../../node_modules/front-components/components/layout/TheHeaderNavRoute.vue' /* webpackChunkName: "components/s2-the-header-nav-route" */).then(c => wrapFunctional(c.default || c))
export const S2TheHeaderUtility = () => import('../../node_modules/front-components/components/layout/TheHeaderUtility.vue' /* webpackChunkName: "components/s2-the-header-utility" */).then(c => wrapFunctional(c.default || c))
export const S2TheLogin = () => import('../../node_modules/front-components/components/layout/TheLogin.vue' /* webpackChunkName: "components/s2-the-login" */).then(c => wrapFunctional(c.default || c))
export const ActionButtons = () => import('../../components/action/ActionButtons.vue' /* webpackChunkName: "components/action-buttons" */).then(c => wrapFunctional(c.default || c))
export const AsideLink = () => import('../../components/aside/AsideLink.vue' /* webpackChunkName: "components/aside-link" */).then(c => wrapFunctional(c.default || c))
export const TheAside = () => import('../../components/aside/TheAside.vue' /* webpackChunkName: "components/the-aside" */).then(c => wrapFunctional(c.default || c))
export const CircleChart = () => import('../../components/chart/CircleChart.vue' /* webpackChunkName: "components/circle-chart" */).then(c => wrapFunctional(c.default || c))
export const DoughnutChart = () => import('../../components/chart/DoughnutChart.vue' /* webpackChunkName: "components/doughnut-chart" */).then(c => wrapFunctional(c.default || c))
export const DoughnutChartInfo = () => import('../../components/chart/DoughnutChartInfo.vue' /* webpackChunkName: "components/doughnut-chart-info" */).then(c => wrapFunctional(c.default || c))
export const PyramidChart = () => import('../../components/chart/PyramidChart.vue' /* webpackChunkName: "components/pyramid-chart" */).then(c => wrapFunctional(c.default || c))
export const UserConfiguration = () => import('../../components/configuration/UserConfiguration.vue' /* webpackChunkName: "components/user-configuration" */).then(c => wrapFunctional(c.default || c))
export const Feedback = () => import('../../components/feedback/Feedback.vue' /* webpackChunkName: "components/feedback" */).then(c => wrapFunctional(c.default || c))
export const FeedbackMessage = () => import('../../components/feedback/FeedbackMessage.vue' /* webpackChunkName: "components/feedback-message" */).then(c => wrapFunctional(c.default || c))
export const DownloadManager = () => import('../../components/file/DownloadManager.vue' /* webpackChunkName: "components/download-manager" */).then(c => wrapFunctional(c.default || c))
export const FileEntry = () => import('../../components/file/FileEntry.vue' /* webpackChunkName: "components/file-entry" */).then(c => wrapFunctional(c.default || c))
export const FileList = () => import('../../components/file/FileList.vue' /* webpackChunkName: "components/file-list" */).then(c => wrapFunctional(c.default || c))
export const FilterButtons = () => import('../../components/filters/FilterButtons.vue' /* webpackChunkName: "components/filter-buttons" */).then(c => wrapFunctional(c.default || c))
export const FilterTag = () => import('../../components/filters/FilterTag.vue' /* webpackChunkName: "components/filter-tag" */).then(c => wrapFunctional(c.default || c))
export const AddButtonInline = () => import('../../components/form/AddButtonInline.vue' /* webpackChunkName: "components/add-button-inline" */).then(c => wrapFunctional(c.default || c))
export const FileUpload = () => import('../../components/form/FileUpload.vue' /* webpackChunkName: "components/file-upload" */).then(c => wrapFunctional(c.default || c))
export const LabeledInput = () => import('../../components/form/LabeledInput.vue' /* webpackChunkName: "components/labeled-input" */).then(c => wrapFunctional(c.default || c))
export const PasswordField = () => import('../../components/form/PasswordField.vue' /* webpackChunkName: "components/password-field" */).then(c => wrapFunctional(c.default || c))
export const PasswordFieldLogin = () => import('../../components/form/PasswordFieldLogin.vue' /* webpackChunkName: "components/password-field-login" */).then(c => wrapFunctional(c.default || c))
export const PinCode = () => import('../../components/form/PinCode.vue' /* webpackChunkName: "components/pin-code" */).then(c => wrapFunctional(c.default || c))
export const PopOver = () => import('../../components/form/PopOver.vue' /* webpackChunkName: "components/pop-over" */).then(c => wrapFunctional(c.default || c))
export const SubmitField = () => import('../../components/form/SubmitField.vue' /* webpackChunkName: "components/submit-field" */).then(c => wrapFunctional(c.default || c))
export const TextField = () => import('../../components/form/TextField.vue' /* webpackChunkName: "components/text-field" */).then(c => wrapFunctional(c.default || c))
export const TextFieldLogin = () => import('../../components/form/TextFieldLogin.vue' /* webpackChunkName: "components/text-field-login" */).then(c => wrapFunctional(c.default || c))
export const EmptyState = () => import('../../components/includes/EmptyState.vue' /* webpackChunkName: "components/empty-state" */).then(c => wrapFunctional(c.default || c))
export const FaqItem = () => import('../../components/includes/FaqItem.vue' /* webpackChunkName: "components/faq-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderBar = () => import('../../components/includes/HeaderBar.vue' /* webpackChunkName: "components/header-bar" */).then(c => wrapFunctional(c.default || c))
export const NewsItem = () => import('../../components/includes/NewsItem.vue' /* webpackChunkName: "components/news-item" */).then(c => wrapFunctional(c.default || c))
export const Paginator = () => import('../../components/includes/Paginator.vue' /* webpackChunkName: "components/paginator" */).then(c => wrapFunctional(c.default || c))
export const Spinner = () => import('../../components/includes/Spinner.vue' /* webpackChunkName: "components/spinner" */).then(c => wrapFunctional(c.default || c))
export const ActOnSelectionDialog = () => import('../../components/modal/ActOnSelectionDialog.vue' /* webpackChunkName: "components/act-on-selection-dialog" */).then(c => wrapFunctional(c.default || c))
export const ConfirmDialog = () => import('../../components/modal/ConfirmDialog.vue' /* webpackChunkName: "components/confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const MoveAgenciesDialog = () => import('../../components/modal/MoveAgenciesDialog.vue' /* webpackChunkName: "components/move-agencies-dialog" */).then(c => wrapFunctional(c.default || c))
export const UploadDialog = () => import('../../components/modal/UploadDialog.vue' /* webpackChunkName: "components/upload-dialog" */).then(c => wrapFunctional(c.default || c))
export const FormFooter = () => import('../../components/panel/FormFooter.vue' /* webpackChunkName: "components/form-footer" */).then(c => wrapFunctional(c.default || c))
export const SidePanel = () => import('../../components/panel/SidePanel.vue' /* webpackChunkName: "components/side-panel" */).then(c => wrapFunctional(c.default || c))
export const HorizontalScrollbar = () => import('../../components/scroll/HorizontalScrollbar.vue' /* webpackChunkName: "components/horizontal-scrollbar" */).then(c => wrapFunctional(c.default || c))
export const Scrollbar = () => import('../../components/scroll/Scrollbar.vue' /* webpackChunkName: "components/scrollbar" */).then(c => wrapFunctional(c.default || c))
export const ScrollbarWrapper = () => import('../../components/scroll/ScrollbarWrapper.vue' /* webpackChunkName: "components/scrollbar-wrapper" */).then(c => wrapFunctional(c.default || c))
export const VerticalScrollbar = () => import('../../components/scroll/VerticalScrollbar.vue' /* webpackChunkName: "components/vertical-scrollbar" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/search/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const ManagedSubtable = () => import('../../components/table/ManagedSubtable.vue' /* webpackChunkName: "components/managed-subtable" */).then(c => wrapFunctional(c.default || c))
export const LineChart = () => import('../../components/chart/line/LineChart.vue' /* webpackChunkName: "components/line-chart" */).then(c => wrapFunctional(c.default || c))
export const MultiLineChart = () => import('../../components/chart/line/MultiLineChart.vue' /* webpackChunkName: "components/multi-line-chart" */).then(c => wrapFunctional(c.default || c))
export const Config = () => import('../../components/chart/line/config.ts' /* webpackChunkName: "components/config" */).then(c => wrapFunctional(c.default || c))
export const NavLink = () => import('../../components/nav/main/NavLink.vue' /* webpackChunkName: "components/nav-link" */).then(c => wrapFunctional(c.default || c))
export const TheNav = () => import('../../components/nav/main/TheNav.vue' /* webpackChunkName: "components/the-nav" */).then(c => wrapFunctional(c.default || c))
export const SecondaryNav = () => import('../../components/nav/secondary/SecondaryNav.vue' /* webpackChunkName: "components/secondary-nav" */).then(c => wrapFunctional(c.default || c))
export const SecondaryNavLink = () => import('../../components/nav/secondary/SecondaryNavLink.vue' /* webpackChunkName: "components/secondary-nav-link" */).then(c => wrapFunctional(c.default || c))
export const ApikeyManager = () => import('../../components/panel/agencies/ApikeyManager.vue' /* webpackChunkName: "components/apikey-manager" */).then(c => wrapFunctional(c.default || c))
export const EditAgencyForm = () => import('../../components/panel/agencies/EditAgencyForm.vue' /* webpackChunkName: "components/edit-agency-form" */).then(c => wrapFunctional(c.default || c))
export const EditAgencyVaccineExceptionForm = () => import('../../components/panel/agencies/EditAgencyVaccineExceptionForm.vue' /* webpackChunkName: "components/edit-agency-vaccine-exception-form" */).then(c => wrapFunctional(c.default || c))
export const ViewAlertForm = () => import('../../components/panel/alerts/ViewAlertForm.vue' /* webpackChunkName: "components/view-alert-form" */).then(c => wrapFunctional(c.default || c))
export const ViewAuditForm = () => import('../../components/panel/audit/ViewAuditForm.vue' /* webpackChunkName: "components/view-audit-form" */).then(c => wrapFunctional(c.default || c))
export const AddCertForm = () => import('../../components/panel/certs/AddCertForm.vue' /* webpackChunkName: "components/add-cert-form" */).then(c => wrapFunctional(c.default || c))
export const EditComputerForm = () => import('../../components/panel/computers/EditComputerForm.vue' /* webpackChunkName: "components/edit-computer-form" */).then(c => wrapFunctional(c.default || c))
export const ViewComputerForm = () => import('../../components/panel/computers/ViewComputerForm.vue' /* webpackChunkName: "components/view-computer-form" */).then(c => wrapFunctional(c.default || c))
export const EditConfigForm = () => import('../../components/panel/config/EditConfigForm.vue' /* webpackChunkName: "components/edit-config-form" */).then(c => wrapFunctional(c.default || c))
export const EditInstanceForm = () => import('../../components/panel/instances/EditInstanceForm.vue' /* webpackChunkName: "components/edit-instance-form" */).then(c => wrapFunctional(c.default || c))
export const TechSupportForm = () => import('../../components/panel/issues/TechSupportForm.vue' /* webpackChunkName: "components/tech-support-form" */).then(c => wrapFunctional(c.default || c))
export const EditNewsItemForm = () => import('../../components/panel/news/EditNewsItemForm.vue' /* webpackChunkName: "components/edit-news-item-form" */).then(c => wrapFunctional(c.default || c))
export const EditSectorForm = () => import('../../components/panel/sectors/EditSectorForm.vue' /* webpackChunkName: "components/edit-sector-form" */).then(c => wrapFunctional(c.default || c))
export const EditUserForm = () => import('../../components/panel/users/EditUserForm.vue' /* webpackChunkName: "components/edit-user-form" */).then(c => wrapFunctional(c.default || c))
export const PasswordChangeForm = () => import('../../components/panel/users/PasswordChangeForm.vue' /* webpackChunkName: "components/password-change-form" */).then(c => wrapFunctional(c.default || c))
export const EditVaccineForm = () => import('../../components/panel/vaccines/EditVaccineForm.vue' /* webpackChunkName: "components/edit-vaccine-form" */).then(c => wrapFunctional(c.default || c))
export const SwitchCell = () => import('../../components/table/cells/SwitchCell.vue' /* webpackChunkName: "components/switch-cell" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
