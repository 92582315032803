//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import Feedback from "~/components/feedback/Feedback.vue";

export default {
  name: "Auth2FALayout",
  components: {
    Feedback,
  },
  middleware: ["notAuthenticated"],

  computed: {
    ...mapState("application", ["taggedVersion"]),
  },
};
