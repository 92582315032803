//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * @typedef {import("@/types/user/user").User} User
 */
import { mapGetters } from "vuex";
import { ROLE } from "~/assets/js/constants";
import { isAllowedTo } from "~/assets/js/utils";
import AsideLink from "~/components/aside/AsideLink.vue";

export default {
  name: "TheAside",
  components: {
    AsideLink,
    UserConfiguration: () => import("~/components/configuration/UserConfiguration.vue"),
    DownloadManager: () => import("~/components/file/DownloadManager.vue"),
  },
  data() {
    return {
      ROLE,
      isAllowedTo,
    };
  },
  computed: {
    ...mapGetters({
      loggedRole: "application/getLoggedRole",
      loggedUser: "application/getLoggedUser",
      arePendingDownloads: "application/arePendingDownloads",
      areDownloadsCompleted: "application/areDownloadsCompleted",
    }),
    hasAgency() {
      /** @type {User} */
      const { loggedUser } = this;

      return loggedUser && loggedUser.agencies.length > 0;
    },
    links() {
      return [
        {
          name: "downloadManager",
          icon: "download-1",
          popover: true,
          component: "download-manager",
          visible: this.arePendingDownloads,
          classNames: !this.areDownloadsCompleted ? "animate-scale-pulse" : "",
        },
        {
          icon: "cog",
          action: "application/navigateToConfig",
          visible: isAllowedTo([ROLE.ADMIN], this.loggedRole),
        },
        {
          icon: "user",
          popover: true,
          component: "user-configuration",
          visible: isAllowedTo([ROLE.ADMIN, ROLE.USER, ROLE.MANAGER], this.loggedRole),
        },
        {
          icon: "question",
          action: "application/navigateToFaqs",
        },
        {
          icon: "envelope-light",
          eventHandler: this.onSupportClick,
          visible: this.hasAgency,
        },
        {
          icon: "book",
          action: "application/downloadDocumentation",
        },
        {
          icon: "power-off",
          action: "authentication/logout",
          button: true,
        },
      ];
    },
  },
  methods: {
    /**
     * @param {Function} eventHandler
     */
    onAsideLinkClick(eventHandler) {
      if (eventHandler) {
        eventHandler();
      }
    },
    onSupportClick() {
      this.$emit("tech-support-panel-open");
    },
  },
};
