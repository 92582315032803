import type { ActionTree, MutationTree } from "vuex";
import { PaginatedResponse, RequestConfigParams, uuid4 } from "~/types/types";
import {
  REJECT_SELECTED_ALERTS,
  RESOLVE_SELECTED_ALERTS,
  SET_ALERT,
  SET_ALERTS,
  SET_CURRENT_ALERT,
  SET_IS_IN_AGENCY,
} from "./alertMutationTypes";

import { ALERT_STATUS } from "~/assets/js/constants";
import urlBuilder from "~/assets/js/urlBuilder";
import DownloadService from "~/services/DownloadService";
import { AlertDTO } from "~/types/alert/alert";
import { SET_LOADING } from "../sharedMutationTypes";

export const state = () => ({
  alerts: null as PaginatedResponse<AlertDTO>,
  loading: false,
  isInAgency: false,
  currentAlert: null as AlertDTO,
});
export type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  [SET_CURRENT_ALERT](state, alertId: uuid4) {
    state.currentAlert = state.alerts.collection.find((alert) => alert.id === alertId);
  },
  [SET_IS_IN_AGENCY](state, isInAgency: boolean) {
    state.isInAgency = isInAgency;
  },
  [SET_ALERTS](state, alerts: PaginatedResponse<AlertDTO>) {
    state.alerts = alerts;
  },
  [SET_LOADING](state, loading: boolean) {
    state.loading = loading;
  },
  [SET_ALERT](state, updatedAlert: AlertDTO) {
    const index = state.alerts.collection.findIndex((alert) => alert.id === updatedAlert.id);

    if (index >= 0) {
      state.alerts.collection.splice(index, 1, updatedAlert);
    }
  },
  [RESOLVE_SELECTED_ALERTS](state, selection: uuid4[]) {
    for (const alert of state.alerts.collection) {
      if (selection.includes(alert.id)) {
        alert.status = ALERT_STATUS.RESOLVED;
      }
    }
  },
  [REJECT_SELECTED_ALERTS](state, selection: uuid4[]) {
    for (const alert of state.alerts.collection) {
      if (selection.includes(alert.id)) {
        alert.status = ALERT_STATUS.REJECTED;
      }
    }
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async setCurrentAlert({ commit }, alertId: uuid4) {
    commit(SET_CURRENT_ALERT, alertId);
  },

  async resolveSelectedAlerts({ commit }, { selection }) {
    const apiUrl = `/alerts/bulk/update-status`;
    try {
      await this.$axios.$post(apiUrl, { alerts: selection, status: ALERT_STATUS.RESOLVED });
      commit(RESOLVE_SELECTED_ALERTS, selection);
      this.$feedback.ok("feedback.resolve.alerts.bulk.ok");
    } catch (error) {
      this.$feedback.error("feedback.resolve.alerts.bulk.error");
    }
  },
  async rejectSelectedAlerts({ commit }, { selection }) {
    const apiUrl = `/alerts/bulk/update-status`;
    try {
      await this.$axios.$post(apiUrl, { alerts: selection, status: ALERT_STATUS.REJECTED });
      commit(REJECT_SELECTED_ALERTS, selection);
      this.$feedback.ok("feedback.reject.alerts.bulk.ok");
    } catch (error) {
      this.$feedback.error("feedback.reject.alerts.bulk.error");
    }
  },
  async fetch({ dispatch, commit }, args) {
    commit(SET_LOADING, true);
    try {
      dispatch("setIsInAgency", args);
      await dispatch("setAlerts", args);
    } finally {
      commit(SET_LOADING, false);
    }
  },

  setIsInAgency({ commit }, args) {
    const { route } = args;
    if (route.params.agency !== undefined) {
      commit(SET_IS_IN_AGENCY, true);
    }
  },

  setLoading({ commit }, loading) {
    commit(SET_LOADING, loading);
  },

  async setAlerts({ commit, rootGetters }, requestParams: RequestConfigParams = {}) {
    const { sortBy, orderBy, page, filter, defaultFilter } = requestParams;
    const size = rootGetters["application/getPageSize"];
    const { agencyId } = rootGetters["application/getSelectedAgency"];

    const endpoint = !agencyId ? `alerts` : `agencies/${agencyId}/alerts`;
    const apiUrl = urlBuilder(endpoint, {
      size,
      page,
      sortBy,
      orderBy,
      filter,
      defaultFilter,
    });

    try {
      const alerts: PaginatedResponse<AlertDTO> = await this.$axios.$get(apiUrl);
      commit(SET_ALERTS, alerts);
    } catch (error) {
      throw new Error(error);
    }
  },

  async setAlert({ commit }, { status, route, id }) {
    try {
      const agencyId = route.params.agency;
      const url = agencyId ? `/alerts/${id}` : `alerts/${id}`;
      const updatedAlert = await this.$axios.$patch<AlertDTO>(url, { status });

      commit(SET_ALERT, updatedAlert);
      this.$feedback.ok("feedback.update.alert.ok");
    } catch (error) {
      this.$feedback.error("feedback.update.alert.error");
    }
  },

  async prepareCsv({ state, rootGetters, commit, dispatch }, requestParams: RequestConfigParams) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];
    const endpoint = state.isInAgency
      ? `agencies/${agencyId}/alerts/preparecsv`
      : `alerts/preparecsv`;

    await DownloadService.prepareDownload(this.$axios, endpoint, requestParams, {
      dispatch,
      commit,
      $feedback: this.$feedback,
    });
  },
};
