import type { ActionTree, MutationTree } from "vuex";
import { IssueSaveDTO, IssueTypeDTO } from "~/types/issue/issue";

import { SET_LOADING } from "@/store/sharedMutationTypes";
import urlBuilder from "~/assets/js/urlBuilder";
import { SET_ISSUE_TYPE_LIST } from "./issuesMutationTypes";

export const state = () => ({
  issueTypeList: null as IssueTypeDTO[],
  loading: false,
});

type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  [SET_ISSUE_TYPE_LIST](state: RootState, issueTypeList: IssueTypeDTO[]) {
    state.issueTypeList = issueTypeList;
  },
  [SET_LOADING](state: RootState, loading: boolean) {
    state.loading = loading;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async setIssueTypeList({ commit }) {
    const apiUrl = urlBuilder("issues/types", {});

    try {
      const issueTypes: IssueTypeDTO[] = await this.$axios.$get(apiUrl);

      commit(SET_ISSUE_TYPE_LIST, issueTypes);
    } catch (error) {
      throw new Error(error);
    }
  },

  async addIssue(_context, savedIssue: IssueSaveDTO) {
    try {
      await this.$axios.$post<IssueSaveDTO>("issues", { ...savedIssue });
      this.$feedback.ok("feedback.create.issues.ok");
    } catch (error) {
      this.$feedback.error("feedback.create.issues.error");
    }
  },
};
