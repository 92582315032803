//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "FormFooter",
  components: {},
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    onCancel() {
      this.$emit("form-cancel");
    },
    onAccept() {
      this.$emit("form-accept");
    },
  },
};
