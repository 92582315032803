import { render, staticRenderFns } from "./main.vue?vue&type=template&id=09903713&"
import script from "./main.vue?vue&type=script&lang=js&"
export * from "./main.vue?vue&type=script&lang=js&"
import style0 from "./main.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TechSupportForm: require('/usr/src/app/components/panel/issues/TechSupportForm.vue').default,SidePanel: require('/usr/src/app/components/panel/SidePanel.vue').default,TheAside: require('/usr/src/app/components/aside/TheAside.vue').default,TheNav: require('/usr/src/app/components/nav/main/TheNav.vue').default,SecondaryNav: require('/usr/src/app/components/nav/secondary/SecondaryNav.vue').default,Feedback: require('/usr/src/app/components/feedback/Feedback.vue').default})
