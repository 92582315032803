//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import Feedback from "~/components/feedback/Feedback.vue";

export default {
  name: "RenewIndex",
  components: {
    Feedback,
  },
  middleware: ["notAuthenticated"],

  data() {
    return {
      fullYear: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapState("application", ["unverifiedUsername", "taggedVersion"]),
  },
  beforeMount() {
    if (!this.unverifiedUsername) {
      this.$router.push(`/login`);
    }
  },
};
