/* eslint-disable max-lines */

import type { ActionTree, MutationTree } from "vuex";
import { AgencyDTO, AgencySaveDTO, AgencyVaccineExceptionDTO } from "~/types/agency/agency";
import { SectorDTO, SectorSaveDTO } from "~/types/sector/sector";
import { PaginatedResponse, RequestConfigParams, agencyid, instanceid, uuid4 } from "~/types/types";
import {
  ADD_AGENCY,
  ADD_SECTOR,
  DELETE_AGENCY,
  DELETE_SECTOR,
  IS_FORM_BACKEND_VALIDATED,
  REMOVE_AGENCIES,
  RESET_OPEN_AGENCIES,
  SET_AGENCIES,
  SET_AGENCY,
  SET_AGENCY_LIST,
  SET_CURRENT_AGENCY,
  SET_CURRENT_SECTOR,
  SET_EDITING_AGENCY_VACCINE_EXCEPTIONS,
  SET_INSTANCE_ID,
  SET_SECTOR,
  SET_SECTORS,
  SET_SECTOR_LIST,
} from "./agencyMutationTypes";

import { ROLE } from "~/assets/js/constants";
import urlBuilder from "~/assets/js/urlBuilder";
import { isAllowedTo, orderMapper } from "~/assets/js/utils";
import AgencyService from "~/services/AgencyService";
import DownloadService from "~/services/DownloadService";
import { SET_LOADING } from "../sharedMutationTypes";

export const state = () => ({
  sectors: null as PaginatedResponse<SectorDTO>,
  sectorList: null as PaginatedResponse<SectorDTO>,
  agencyList: null as PaginatedResponse<AgencyDTO>,
  agencies: {} as { [key: string]: PaginatedResponse<AgencyDTO> },
  loading: false,
  instanceId: null,
  currentSector: null as SectorDTO,
  currentAgency: null as AgencyDTO,
  isFormBackendValidated: false,
  editingAgencyVaccineException: null as AgencyVaccineExceptionDTO,
});

type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  [SET_EDITING_AGENCY_VACCINE_EXCEPTIONS](
    state,
    editingAgencyVaccineException: AgencyVaccineExceptionDTO,
  ) {
    state.editingAgencyVaccineException = editingAgencyVaccineException;
  },

  [IS_FORM_BACKEND_VALIDATED](state, value: boolean) {
    state.isFormBackendValidated = value;
  },

  [RESET_OPEN_AGENCIES](state) {
    state.agencies = {};
  },

  [DELETE_AGENCY](state, { agencyId, sectorId }: { agencyId: agencyid; sectorId: uuid4 }) {
    const index = state.agencies[sectorId].collection.findIndex((agency) => agency.id === agencyId);
    const isFound = index !== -1;
    if (isFound) {
      const agencyToDelete = state.agencies[sectorId].collection[index];
      const agencySectorId = agencyToDelete.metadata.sectorId;

      state.agencies[sectorId].collection.splice(index, 1);

      // We udpate also the sector count
      const sectorIndex = state.sectors.collection.findIndex(
        (sector) => sector.id === agencySectorId,
      );
      const sector = state.sectors.collection[sectorIndex];
      sector.count--;
    }
  },

  [ADD_AGENCY](state, agency: AgencyDTO) {
    // We add the agency
    const parent = agency.metadata.sectorId;
    const sectorAgencies = state.agencies[parent];

    if (sectorAgencies) {
      sectorAgencies.collection.unshift(agency);
    }

    // We udpate also the sector count
    const sectorIndex = state.sectors.collection.findIndex((sector) => sector.id === parent);
    const sector = state.sectors.collection[sectorIndex];
    sector.count++;
  },

  [DELETE_SECTOR](state, id: uuid4) {
    const index = state.sectors.collection.findIndex((sector) => {
      return sector.id === id;
    });
    const isSectorFound = index >= 0;

    if (isSectorFound) {
      state.sectors.collection.splice(index, 1);
    }
  },

  [SET_AGENCY](state, updatedAgency: AgencyDTO) {
    const parent = updatedAgency.metadata.sectorId;
    const sectorAgencies = state.agencies[parent];

    if (sectorAgencies) {
      const index = sectorAgencies.collection.findIndex((agency) => agency.id === updatedAgency.id);
      const isAgencyFound = index >= 0;
      if (isAgencyFound) {
        sectorAgencies.collection.splice(index, 1, updatedAgency);
      }
    }
  },

  [SET_SECTOR](state, updatedSector) {
    const index = state.sectors.collection.findIndex((sector) => {
      return sector.id === updatedSector.id;
    });
    const isFound = index !== -1;

    if (isFound) {
      state.sectors.collection.splice(index, 1, updatedSector);
    }
  },

  [ADD_SECTOR](state, sector: SectorDTO) {
    state.sectors.collection.unshift(sector);
  },

  [SET_AGENCIES](
    state,
    { parent, agencies }: { parent: uuid4; agencies: PaginatedResponse<AgencyDTO> },
  ) {
    this._vm.$set(state.agencies, parent, agencies);
  },

  [REMOVE_AGENCIES](state, { parent }: { parent: uuid4 }) {
    this._vm.$delete(state.agencies, parent);
  },

  [SET_INSTANCE_ID](state, instanceId) {
    state.instanceId = instanceId;
  },

  [SET_SECTORS](state, sectors: PaginatedResponse<SectorDTO>) {
    state.sectors = sectors;
  },

  [SET_CURRENT_SECTOR](state, currentSector: SectorDTO) {
    state.currentSector = currentSector;
  },

  [SET_CURRENT_AGENCY](state, currentAgency: AgencyDTO) {
    state.currentAgency = currentAgency;
  },

  [SET_SECTOR_LIST](state, sectors: PaginatedResponse<SectorDTO>) {
    state.sectorList = sectors;
  },

  [SET_AGENCY_LIST](state, agencies: PaginatedResponse<AgencyDTO>) {
    state.agencyList = agencies;
  },

  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async fetchAgencyVaccineException(
    { commit },
    { agencyId, vaccineId }: { agencyId: agencyid; vaccineId: uuid4 },
  ) {
    try {
      const agencyVaccineException = await AgencyService.vaccineException(
        this.$axios,
        agencyId,
        vaccineId,
      );
      commit(SET_EDITING_AGENCY_VACCINE_EXCEPTIONS, agencyVaccineException);
    } catch (error) {
      throw new Error(error);
    }
  },

  async setAgencyVaccineException(
    context,
    {
      agencyId,
      vaccineId,
      savedAgencyVaccineException,
    }: {
      agencyId: agencyid;
      vaccineId: uuid4;
      savedAgencyVaccineException: AgencyVaccineExceptionDTO;
    },
  ) {
    try {
      await AgencyService.setVaccineException(
        this.$axios,
        agencyId,
        vaccineId,
        savedAgencyVaccineException,
      );
      this.$feedback.ok("feedback.update.agencyException.ok");
    } catch (error) {
      this.$feedback.error("feedback.update.agencyException.error");
    }
  },

  async setCurrentSector({ commit }, id) {
    try {
      const currentSector = await this.$axios.$get<SectorDTO>(`sectors/${id}`);
      commit(SET_CURRENT_SECTOR, currentSector);
    } catch (error) {
      throw new Error(error);
    }
  },

  async setCurrentAgency({ commit }, id) {
    try {
      const currentAgency = await this.$axios.$get<AgencyDTO>(`agencies/${id}`);
      commit(SET_CURRENT_AGENCY, currentAgency);
    } catch (error) {
      throw new Error(error);
    }
  },

  async moveSelectedAgencies(
    { state, dispatch },
    { destination, agencies, sectorId, certId, actOnChildren },
  ) {
    const { instanceId } = state;

    const apiUrl = `/instances/${instanceId}/agencies/move`;
    try {
      await this.$axios.$post(apiUrl, {
        sectorId,
        agencies,
        certId,
        instanceId: destination,
        moveAgenciesChildren: actOnChildren,
      });
      dispatch("fetch", {});

      this.$feedback.ok("agencies.globalActions.move.ok");
    } catch (error) {
      this.$feedback.error("agencies.globalActions.move.error");
    }
  },

  async downloadCsv({ state, rootGetters }, { filter }: { filter: string }) {
    let url = "";
    const { instanceId } = state;
    await this.$axios
      .$post(`agency/downloadCsv?instanceId=${instanceId}`, filter, {
        responseType: "blob",
        cancelToken: rootGetters["application/getCancelToken"].token,
      })
      .then((response) => {
        url = window.URL.createObjectURL(new Blob([response]));
      });
    const link = document.createElement("a");
    const title = this.$i18n.t("agencies.label") as string;

    link.href = url;
    link.setAttribute("download", `microclaudia - ${title.toLowerCase()}.csv`);
    document.body.appendChild(link);
    link.click();
  },

  async fetch({ dispatch, commit, rootGetters }, args) {
    commit(SET_LOADING, true);
    try {
      const { isFederatedView = false, instanceId = rootGetters["application/getMainInstanceId"] } =
        args;

      await this.$axios.get(`instances`);

      commit(SET_INSTANCE_ID, instanceId);
      commit(RESET_OPEN_AGENCIES);
      dispatch("application/setFederatedView", isFederatedView, { root: true });

      await dispatch("setSectors");
      await dispatch("setSectorList");
      await dispatch("updateAgenciesList");
    } finally {
      commit(SET_LOADING, false);
    }
  },

  setLoading({ commit }, loading) {
    commit(SET_LOADING, loading);
  },

  async updateAllAgents({ state }) {
    const { instanceId } = state;
    try {
      await AgencyService.updateAllAgents(this.$axios, instanceId);
      this.$feedback.ok("feedback.update.allAgents.ok");
    } catch (error) {
      this.$feedback.error("feedback.update.allAgents.error");
    }
  },

  async updateSelectedAgents(
    { state },
    { selection, actOnChildren }: { selection: agencyid[]; actOnChildren: boolean },
  ) {
    const { instanceId } = state;
    try {
      await AgencyService.updateSelectedAgents(this.$axios, instanceId, selection, actOnChildren);
      this.$feedback.ok("feedback.update.selectedAgents.ok");
    } catch (error) {
      this.$feedback.error("feedback.update.selectedAgents.error");
    }
  },

  async deleteAgency({ commit }, { agencyId, sectorId }: { agencyId: agencyid; sectorId }) {
    try {
      await this.$axios.delete(`agencies/${agencyId}`);
      commit(DELETE_AGENCY, { agencyId, sectorId });
      this.$feedback.ok("feedback.delete.agency.ok");
    } catch (error) {
      this.$feedback.error("feedback.delete.agency.error");
    }
  },

  async deleteSector({ commit }, id: uuid4) {
    try {
      await this.$axios.delete(`sectors/${id}`);
      commit(DELETE_SECTOR, id);
      this.$feedback.ok("feedback.delete.sector.ok");
    } catch (error) {
      this.$feedback.error("feedback.delete.sector.error");
    }
  },

  async setSector({ commit }, { savedSector, id }: { savedSector: SectorSaveDTO; id: uuid4 }) {
    try {
      const sector = await this.$axios.$patch(`sectors/${id}`, savedSector);
      commit(SET_SECTOR, sector);
      this.$feedback.ok("feedback.update.sector.ok");
    } catch (error) {
      this.$feedback.error("feedback.update.sector.error");
    }
  },

  async addSector({ commit }, sector: SectorSaveDTO) {
    try {
      const createdSector: SectorDTO = await this.$axios.$post("sectors", sector);
      commit(ADD_SECTOR, createdSector);
      this.$feedback.ok("feedback.create.sector.ok");
    } catch (error) {
      this.$feedback.error("feedback.create.sector.error");
    }
  },

  async setAgency(
    { commit, dispatch },
    { savedAgency, id }: { savedAgency: AgencySaveDTO; id: agencyid },
  ) {
    await AgencyService.setAgency(this.$axios, savedAgency, id, {
      commit,
      $feedback: this.$feedback,
    });
    dispatch("application/updateNavLink", { savedAgency, id }, { root: true });
  },

  async addAgency({ commit }, agency: AgencyDTO) {
    try {
      const createdAgency = await this.$axios.$post<AgencyDTO>("agencies", agency);
      commit(ADD_AGENCY, createdAgency);
      this.$feedback.ok("feedback.create.agency.ok");
    } catch (error) {
      const { error: prefix, message } = error.response.data;
      this.$feedback.error(`${prefix}${message}`);
    }
  },

  async updateAgenciesList({ commit, rootGetters, state }) {
    const role = rootGetters["application/getLoggedRole"];

    if (isAllowedTo([ROLE.ADMIN, ROLE.MANAGER, ROLE.SERVICEDESK], role)) {
      try {
        const agencies = await this.$axios.$get(
          `instances/${state.instanceId}/agencies/all?size=10000`,
        );
        commit(SET_AGENCY_LIST, agencies);
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  async setSectorList(
    { state, commit, dispatch, rootGetters },
    args: { instanceId?: string } = {},
  ) {
    let instanceId: instanceid;
    if (args.instanceId) {
      // eslint-disable-next-line prefer-destructuring
      instanceId = args.instanceId;
    } else {
      // eslint-disable-next-line prefer-destructuring
      instanceId = state.instanceId;
    }
    dispatch("application/renewCancelToken", {}, { root: true });

    try {
      const apiUrl = urlBuilder(`instances/${instanceId}/sectors`, {
        size: 10000,
        page: 0,
        sortBy: "name",
        orderBy: "asc",
      });
      const sectors: PaginatedResponse<SectorDTO> = await this.$axios.$get(apiUrl, {
        cancelToken: rootGetters["application/getCancelToken"].token,
      });

      commit(SET_SECTOR_LIST, sectors);
    } catch (error) {
      throw new Error(error);
    }
  },

  async setSectors({ state, commit, rootGetters }, args: RequestConfigParams = {}) {
    const { sortBy, orderBy, page, filter } = args;
    const { instanceId } = state;
    const size = rootGetters["application/getPageSize"];

    try {
      if (filter) {
        const apiUrl = urlBuilder(`instances/${instanceId}/sectors/search`, {
          size,
          page,
          sortBy,
          orderBy,
          filter,
        });
        const sectors: PaginatedResponse<SectorDTO> = await this.$axios.$get(apiUrl, {
          cancelToken: rootGetters["application/getCancelToken"].token,
        });
        commit(SET_SECTORS, sectors);
        for (const sector of sectors.collection) {
          const { agencies, id: sectorId } = sector;
          commit(SET_AGENCIES, { parent: sectorId, agencies });
        }
      } else {
        commit(RESET_OPEN_AGENCIES);

        const apiUrl = urlBuilder(`instances/${instanceId}/sectors`, {
          size,
          page,
          sortBy,
          orderBy,
          filter,
        });
        const sectors: PaginatedResponse<SectorDTO> = await this.$axios.$get(apiUrl, {
          cancelToken: rootGetters["application/getCancelToken"].token,
        });
        commit(SET_SECTORS, sectors);
      }
    } catch (error) {
      this.$feedback.error("generic.error");
    }
  },

  async fetchAgencies({ commit }, args: { sectorId: uuid4; requestParams: RequestConfigParams }) {
    const { sectorId, requestParams } = args;
    const { page, sortBy, orderBy, filter } = requestParams;

    let { size } = requestParams;

    if (size === undefined) {
      size = 10;
    }

    const endpoint = `sectors/${sectorId}/agencies`;

    const apiUrl = urlBuilder(endpoint, {
      page,
      size,
      sortBy,
      orderBy: orderMapper(orderBy),
      filter,
    });

    try {
      const agencies = await this.$axios.$get<PaginatedResponse<AgencyDTO>>(apiUrl);
      agencies.sortBy = sortBy;
      agencies.orderBy = orderBy;
      commit(SET_AGENCIES, { parent: sectorId, agencies });
    } catch (error) {
      throw new Error(error);
    }
  },

  async removeAgencies({ commit }, sectorId: uuid4) {
    commit(REMOVE_AGENCIES, { parent: sectorId });
  },

  async prepareCsv({ state, commit, dispatch }, requestParams: RequestConfigParams) {
    const { instanceId } = state;
    const endpoint = `/instances/${instanceId}/agencies/preparecsv`;

    await DownloadService.prepareDownload(this.$axios, endpoint, requestParams, {
      dispatch,
      commit,
      $feedback: this.$feedback,
    });
  },
};
