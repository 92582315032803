//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ErrorIndex",
  layout: "main",
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
};
