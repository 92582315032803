import { INSTANCE_TYPE } from "~/assets/js/constants";
import { InstanceDTO, NavLink, PaginatedResponse } from "~/types/types";

export default class NavigationTransformer {
  static fetchFederated(instances: PaginatedResponse<InstanceDTO>): NavLink[] {
    const out = instances.collection
      .filter((instance) => instance.type !== INSTANCE_TYPE.MAIN)
      .map((instance) => {
        const { id, name } = instance;
        return {
          icon: "network",
          textLink: name,
          link: `/federated/${id}`,
          admin: true,
        };
      });

    return out;
  }
}
