import type { ActionTree, MutationTree } from "vuex";
import urlBuilder from "~/assets/js/urlBuilder";
import { PaginatedResponse, RequestConfigParams } from "~/types/types";
import { SET_CONFIG, SET_CONFIGS, SET_EDITING_CONFIG } from "./configMutationTypes";
import { SET_LOADING } from "../sharedMutationTypes";
import { ConfigDTO } from "~/types/config/config";

export const state = () => ({
  configs: null as PaginatedResponse<ConfigDTO>,
  loading: false,
  editingConfig: null as ConfigDTO,
});

type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  [SET_CONFIGS](state: RootState, configs: PaginatedResponse<ConfigDTO>) {
    state.configs = configs;
  },

  [SET_CONFIG](state: RootState, updatedConfig: ConfigDTO) {
    const index = state.configs.collection.findIndex((config) => config.id === updatedConfig.id);

    if (index >= 0) {
      state.configs.collection.splice(index, 1, updatedConfig);
    }
  },

  [SET_EDITING_CONFIG](state, editingConfig: ConfigDTO) {
    state.editingConfig = editingConfig;
  },

  [SET_LOADING](state: RootState, loading: boolean) {
    state.loading = loading;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async fetch({ dispatch, commit }, requestParams: RequestConfigParams) {
    commit(SET_LOADING, true);
    try {
      await dispatch("setConfigs", requestParams);
    } finally {
      commit(SET_LOADING, false);
    }
  },

  async setConfigs({ commit, rootGetters }, requestParams: RequestConfigParams = {}) {
    const { sortBy, orderBy, page, filter } = requestParams;
    const size = rootGetters["application/getPageSize"];

    const apiUrl = urlBuilder("configurations", {
      size,
      page,
      sortBy,
      orderBy,
      filter,
    });

    try {
      const configs: PaginatedResponse<ConfigDTO> = await this.$axios.$get(apiUrl, {});
      commit("SET_CONFIGS", configs);
    } catch (error) {
      throw new Error(error);
    }
  },

  async setConfig({ commit }, config: ConfigDTO) {
    const { property, value, description, id } = config;
    try {
      const body = { property, value, description };
      const config = await this.$axios.$patch(`configurations/${id}`, body);

      commit(SET_CONFIG, config);
      this.$feedback.ok("feedback.update.config.ok");
    } catch (error) {
      this.$feedback.error("feedback.update.config.error");
    }
  },

  async fetchConfig({ commit }, uuid) {
    try {
      const data = await this.$axios.$get<ConfigDTO>(`configurations/${uuid}`);
      commit(SET_EDITING_CONFIG, data);
    } catch (error) {
      throw new Error(error);
    }
  },
};
