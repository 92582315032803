//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";

export default {
  name: "LoginLayout",
  middleware: [
    "notAuthenticated",
    async ({ store }) => {
      await store.dispatch("application/setEnv");
    },
  ],

  data() {
    return {
      fullYear: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapState("application", ["taggedVersion"]),
    copyright() {
      return ` • © S2 GRUPO ${new Date().getFullYear()}`;
    },
  },
};
