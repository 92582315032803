import { ROLE } from "~/assets/js/constants";

type ACL = {
  allowedTo?: ROLE[];
  forbiddenTo?: ROLE[];
};

export default function ({ store, route, redirect }) {
  if (!route.meta[0].acl || (!route.meta[0].acl.allowedTo && !route.meta[0].acl.forbiddenTo)) {
    throw new Error("Not configured. Include a properly configured acl property in meta");
  }

  const { allowedTo, forbiddenTo } = route.meta[0].acl as ACL;

  const loggedRole = store.getters["application/getLoggedRole"];

  if (allowedTo && !allowedTo.includes(loggedRole)) {
    return redirect(`/`);
  }
  if (forbiddenTo && forbiddenTo.includes(loggedRole)) {
    return redirect(`/`);
  }
}
