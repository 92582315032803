import urlBuilder from "~/assets/js/urlBuilder";
import { AgencyDTO } from "~/types/agency/agency";

import {
  HttpClient,
  PaginatedResponse,
  RequestConfigParams,
  instanceid,
  semver,
} from "~/types/types";
import { VersionDTO } from "~/types/version/version";

export default class VersionControlService {
  static async getVersions(
    httpClient: HttpClient,
    instanceId: instanceid,
    requestParams: RequestConfigParams = {},
  ): Promise<PaginatedResponse<VersionDTO>> {
    const { page, sortBy, orderBy, filter, size } = requestParams;

    const endpoint = `/instances/${instanceId}/versions`;
    const apiUrl = urlBuilder(endpoint, {
      size,
      page,
      sortBy,
      orderBy,
      filter,
    });

    const response = await httpClient.get(apiUrl);

    return response.data as PaginatedResponse<VersionDTO>;
  }

  static async getAgenciesByVersion(
    httpClient: HttpClient,
    instanceId: instanceid,
    version: semver,
    requestParams: RequestConfigParams = {},
  ): Promise<PaginatedResponse<AgencyDTO>> {
    const { page, size = 10, sortBy, orderBy, filter } = requestParams;

    const endpoint = `/instances/${instanceId}/versions/${version}/agencies`;

    const apiUrl = urlBuilder(endpoint, {
      page,
      size,
      sortBy,
      orderBy,
      filter,
    });

    const response = await httpClient.get(apiUrl);

    return response.data as PaginatedResponse<AgencyDTO>;
  }
}
