//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SidePanel",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    zIndex: {
      type: String,
      default: "z-50",
    },
  },
  data() {
    return {
      isBackdropVisible: false,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(newValue) {
        this.$emit("update:open", newValue);
      },
    },
  },
  watch: {
    open(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.backdrop.focus();
        });
      }
    },
  },
  methods: {
    onEscKeyUp() {},
    onClose() {
      this.isOpen = false;
      this.$emit("panel-close");
    },
    /* istanbul ignore next */
    transitionEnter(el, done) {
      el.style.right = `-${el.offsetWidth}px`;
      this.$velocity(
        el,
        { right: "0px" },
        {
          duration: 250,
          easing: "ease-in-out",
          complete: () => {
            this.isBackdropVisible = true;
            done();
          },
        },
      );
    },
    /* istanbul ignore next */
    transitionLeave(el, done) {
      this.$velocity(
        el,
        { right: `-${el.offsetWidth}px` },
        {
          duration: 250,
          easing: "ease-in-out",
          complete: () => {
            this.isBackdropVisible = false;
            done();
          },
        },
      );
    },
  },
};
