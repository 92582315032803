import { UIMessages, ValidationResult } from "~/types/validation/validation";

export class BaseValidator {
  errors: string[] = [];
  messages: UIMessages;

  protected validationResult(): ValidationResult {
    return {
      errors: this.errors,
      messages: this.messages,
      hasErrors: this.errors.length > 0,
      isValid: this.errors.length === 0,
    };
  }
}
