import FloatingVue from "floating-vue";
import Vue from "vue";

Vue.use(FloatingVue, {
  themes: {
    default: {
      $extend: "tooltip",
    },
    legend: {
      $extend: "tooltip",
    },
  },
});
