//
//
//
//
//
//
//
//

export default {
  name: "LabeledInput",
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
  },
  computed: {
    requiredMarker() {
      return this.required ? "*" : "";
    },
  },
  methods: {
    onInput() {},
  },
};
