import { render, staticRenderFns } from "./SecondaryNavLink.vue?vue&type=template&id=bfd940f4&scoped=true&"
import script from "./SecondaryNavLink.vue?vue&type=script&lang=js&"
export * from "./SecondaryNavLink.vue?vue&type=script&lang=js&"
import style0 from "./SecondaryNavLink.vue?vue&type=style&index=0&id=bfd940f4&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfd940f4",
  null
  
)

export default component.exports