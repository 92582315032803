//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * @typedef {import("@/types/issue/issue").IssueSaveDTO} IssueSaveDTO
 * @typedef {import("@/types/user/user").User} User
 */
import FormFooter from "@/components/panel/FormFooter.vue";
import { IssueValidator } from "@/validators/IssueValidator";
import { mapActions, mapState, mapGetters } from "vuex";
import { displayError } from "@/assets/js/utils";
import IssuesTransformer from "~/transformers/IssuesTransformer";

export default {
  name: "TechSupportForm",
  components: { FormFooter },
  props: {},
  data() {
    return {
      title: "",
      description: "",
      selectedIssueType: "1",
      showErrors: false,
      isValid: false,
      isLoading: false,
      errors: [],
      errorMessages: {},
      agencyIds: [],

      displayError,
    };
  },
  computed: {
    ...mapGetters({
      loggedUser: "application/getLoggedUser",
    }),

    ...mapState("issues", ["issueTypeList"]),

    belongsToSingleAgency() {
      /** @type {User} */
      const { loggedUser } = this;

      return loggedUser.agencies.length === 1;
    },

    isDisabled() {
      return this.isLoading;
    },

    issueTypeOptions() {
      return IssuesTransformer.issueTypeOptions(this.issueTypeList, this.$i18n);
    },

    agencyOptions() {
      if (this.loggedUser.agencies && this.loggedUser.agencies.length > 0) {
        return this.loggedUser.agencies.map((agency) => ({
          id: `${agency.id}`,
          label: agency.name,
        }));
      }
      return [];
    },
  },

  async created() {
    this.isLoading = true;

    await this.$store.dispatch("issues/setIssueTypeList");

    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      addIssue: "issues/addIssue",
    }),

    /**
     * @param {uuid4[]} selectedAgencies
     */
    onAgencySelect(selectedAgencies) {
      this.agencyIds = [selectedAgencies[selectedAgencies.length - 1]];
    },

    async onAccept() {
      this.errorMessages = [];
      this.errors = [];

      /** @type {IssueSaveDTO} */
      const savedIssue = {
        title: this.title,
        description: this.description,
        type: this.selectedIssueType,
        agencyId: this.belongsToSingleAgency ? this.loggedUser.agencies[0].id : this.agencyIds[0],
      };

      const validator = new IssueValidator(savedIssue);
      const validation = validator.validate(savedIssue);

      this.errors = validation.errors;
      this.isValid = validation.isValid;

      if (this.isValid) {
        this.showErrors = false;

        await this.addIssue(savedIssue);

        this.$emit("panel-close");
      } else {
        this.errorMessages = validation.messages;
        this.showErrors = true;
      }
    },

    onCancel() {
      this.$emit("panel-close");
    },
  },
};
