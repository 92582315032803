import { NotValidIssue } from "~/errors/NotValidIssue";

import { BaseValidator } from "./BaseValidator";
import { UIMessages, ValidationResult, Validator } from "~/types/validation/validation";
import { IssueSaveDTO } from "~/types/issue/issue";

export class IssueValidator extends BaseValidator implements Validator<IssueSaveDTO> {
  messages: UIMessages = {
    title: [],
    agency: [],
  };

  validate(issue: IssueSaveDTO): ValidationResult {
    if (!issue) {
      throw new NotValidIssue("No issue provided");
    }
    this.validateTitle(issue.title);
    this.validateAgency(issue.agencyId);

    return this.validationResult();
  }

  validateTitle(title: string): ValidationResult {
    if (!title) {
      this.messages.title.push("required");
      this.errors.push("title is required");
    }
    return this.validationResult();
  }

  validateAgency(agency: string): ValidationResult {
    if (!agency) {
      this.messages.agency.push("required");
      this.errors.push("agency is required");
    }
    return this.validationResult();
  }
}
