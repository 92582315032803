//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SpinnerComponent",
  props: {
    negative: {
      type: Boolean,
      default: false,
    },
  },
};
