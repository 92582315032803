import colors from "~/assets/theme/colors";
import { EvolutionStats, circleDefinition, torusDefinition } from "~/types/dashboard/dashboard";

class DashboardTransformer {
  static circle({ index, title, title2, subtitle, value }: circleDefinition) {
    const stylesCircles = [
      "bg-status-warning text-back-lightest",
      "bg-primary-dark text-back-lightest",
      "bg-primary text-back-lightest",
      "bg-primary-light text-back-lightest",
    ];
    return {
      title,
      subtitle,
      title2,
      value,
      styles: stylesCircles[index % stylesCircles.length],
    };
  }

  static doughnutsCollection(
    items,
    {
      title,
      measure,
      emptyMessage,
      alert = true,
      wilcardKey,
      wilcardKeyTranslation,
    }: torusDefinition,
  ) {
    return {
      title,
      emptyMessage,
      alert,
      elements: [
        ...items.map((item, index) =>
          this.doughnut(item, index, measure, wilcardKey, wilcardKeyTranslation),
        ),
      ],
    };
  }

  static doughnut(item, index, measure, wilcardKey, wilcardKeyTranslation) {
    const colorsDoughnut = [
      colors.primary.DEFAULT,
      colors.primary.dark,
      colors.primary.darkest,
      colors.primary.light,
      colors.primary.lightest,
    ];

    let name = "";
    if (item.name === wilcardKey) {
      name = wilcardKeyTranslation;
    } else {
      name = item.name || "";
    }
    return {
      name,
      measure,
      value: item.value || item.days || 0,
      color: colorsDoughnut[index % colorsDoughnut.length],
    };
  }

  static pyramidsCollection(items, title, emptyMessage, alert) {
    return {
      title,
      emptyMessage,
      alert,
      elements: [...items.map((item) => this.pyramids(item))],
    };
  }

  static pyramids(item) {
    return {
      name: item.name || "",
      value: item.value || 0,
    };
  }

  static computerEvolutionCollection(stats: EvolutionStats[]) {
    return {
      labels: [...stats.map((s) => s.updatedOn)],
      datasets: [
        {
          label: "",
          data: [...stats.map((s) => s.agents)],
        },
      ],
    };
  }

  static vaccineEvolutionCollection(stats: EvolutionStats[]) {
    return {
      labels: [...stats.map((s) => s.updatedOn)],
      datasets: [
        {
          label: "",
          data: [...stats.map((s) => s.vaccines)],
        },
      ],
    };
  }

  static alertEvolutionCollection(stats: EvolutionStats[]) {
    return {
      labels: [...stats.map((s) => s.updatedOn)],
      datasets: [
        {
          label: "",
          data: [...stats.map((s) => s.alerts)],
        },
      ],
    };
  }

  static alertsPerDayEvolutionCollection(stats: EvolutionStats[]) {
    return {
      labels: [...stats.map((s) => s.updatedOn)],
      datasets: [
        {
          label: "",
          data: [...stats.map((s) => s.alertsPerDay)],
        },
      ],
    };
  }

  static agencyEvolutionCollection(stats: EvolutionStats[]) {
    return {
      labels: [...stats.map((s) => s.updatedOn)],
      datasets: [
        {
          label: "",
          data: [...stats.map((s) => s.agencies)],
        },
      ],
    };
  }

  static agentsPerDayEvolutionCollection(stats: EvolutionStats[]) {
    return {
      labels: [...stats.map((s) => s.updatedOn)],
      datasets: [
        {
          label: "",
          data: [...stats.map((s) => s.agentsPerDay)],
        },
      ],
    };
  }

  static alertsPerVaccineEvolutionCollection(stats: EvolutionStats[]) {
    const datasetsMap = new Map();
    for (const stat of stats) {
      const { vaccineAlerts } = stat;
      for (const vaccineAlert of vaccineAlerts) {
        if (!datasetsMap.has(vaccineAlert.vaccine)) {
          datasetsMap.set(vaccineAlert.vaccine, vaccineAlert.alerts);
        } else {
          const value = [datasetsMap.get(vaccineAlert.vaccine), vaccineAlert.alerts].flat();
          datasetsMap.set(vaccineAlert.vaccine, value);
        }
      }
    }

    const datasets = Array.from(datasetsMap.entries()).map(([key, val]) => ({
      label: key,
      data: val,
    }));

    return {
      labels: [...stats.map((s) => s.updatedOn)],
      datasets,
    };
  }

  static agentsPerVersionEvolutionCollection(stats: EvolutionStats[]) {
    const datasetsMap = new Map();
    for (const stat of stats) {
      const { versionAgents } = stat;
      for (const versionAgent of versionAgents) {
        if (!datasetsMap.has(versionAgent.version)) {
          datasetsMap.set(versionAgent.version, [versionAgent.agents]);
        } else {
          const value = [datasetsMap.get(versionAgent.version), versionAgent.agents].flat();
          datasetsMap.set(versionAgent.version, value);
        }
      }
    }

    const datasets = Array.from(datasetsMap.entries()).map(([key, val]) => ({
      label: key,
      data: val,
    }));

    return {
      labels: [...stats.map((s) => s.updatedOn)],
      datasets,
    };
  }
}
export default DashboardTransformer;
